import { Box, Button, Typography } from "@mui/material";
import { useLatestSubscription } from "hooks/api/payments/queries";
import { useNavigate } from "react-router-dom";

export default function SubscriptionStrip() {
    const navigate = useNavigate();
    const { data: subscription, isPending } = useLatestSubscription();
    if (isPending || !subscription || subscription?.price.nickname !== "freebie") {
        return null;
    }
    return (
        <Box
            height={"32px"}
            sx={{
                background: "#EFBA42",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "8px",
            }}
        >
            <Typography
                fontSize={"12px"}
                sx={{
                    color: "#081028",
                }}
            >
                You are using free subscription for emailzap with limited access to all the features. Please upgrade
                your acount.
            </Typography>
            <Button
                disableElevation
                disableRipple
                sx={{
                    background: "#0D0E23",
                    color: "white",
                    fontSize: "12px",
                    height: "20px",
                }}
                onClick={() => {
                    navigate("/subscription");
                }}
                data-testid="subscription-strip-upgrade-button"
            >
                Upgrade
            </Button>
        </Box>
    );
}
