import { Box, Button, Grid2, Typography } from "@mui/material";
import { useUpdateUserRole } from "hooks/api/mailbot/mutations";
import { useCustomMediaQuery } from "hooks/useMediaQuery";
import { useState } from "react";

const carrerTitles = [
    "Founder",
    "Executive",
    "Investor",
    "Sales",
    "Marketing",
    "Customer Support",
    "Software Engineer",
    "Other",
];

export default function IntroScreen({ onNext }) {
    const [selectedTitle, setSelectedTitle] = useState("");
    const userRoleMutation = useUpdateUserRole();
    const { md } = useCustomMediaQuery();
    return (
        <Box width={"100%"} height={"100%"}>
            <Box marginBottom={"32px"}>
                <Typography gutterBottom fontWeight={600} fontSize={md ? "24px" : "20px"} sx={{ color: "#000000" }}>
                    Tell us about who you are
                </Typography>
                <Typography fontWeight={400} sx={{ color: "#5D5D6A" }} variant={md ? "body1" : "body2"}>
                    Select an option about which role best describes you?
                </Typography>
            </Box>
            <Grid2 container rowSpacing={md ? 4 : 1}>
                {carrerTitles.map((title) => (
                    <Grid2 size={md ? 3 : 12}>
                        <Box maxWidth={md ? "160px" : "335px"}>
                            <Button
                                fullWidth
                                variant="outlined"
                                disableRipple
                                disableElevation
                                sx={{
                                    borderColor: title === selectedTitle ? "#EFBA42" : "#D8DADF",
                                    fontWeight: title === selectedTitle ? 600 : 400,
                                    color: "#151619",
                                    height: "44px",
                                }}
                                onClick={() => {
                                    setSelectedTitle(title);
                                }}
                            >
                                {title}
                            </Button>
                        </Box>
                    </Grid2>
                ))}
            </Grid2>
            <Box
                sx={{
                    width: md ? "175px" : "335px",
                    marginTop: md ? "374px" : "100px",
                }}
            >
                <Button
                    variant="contained"
                    fullWidth
                    disableElevation
                    disableRipple
                    sx={{
                        height: "41px",
                        color: "black",
                        fontWeight: 600,
                        fontSize: "14px",
                    }}
                    disabled={selectedTitle === ""}
                    onClick={() => {
                        userRoleMutation.mutate(
                            { userRole: selectedTitle },
                            {
                                onSuccess: () => {
                                    onNext();
                                },
                            },
                        );
                    }}
                >
                    Next
                </Button>
            </Box>
        </Box>
    );
}
