import { backendServerAxiosInstance } from "services";

export const getAutoCleanerProfiles = async ({ page, pageSize, queryParams = {} }) => {
    const queryString = Object.keys(queryParams)
        .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
        .join("&");
    const url = `/mailbot/auto-cleaner/?${queryString}&limit=${pageSize}&offset=${(page - 1) * pageSize}`;
    const response = await backendServerAxiosInstance.get(url);
    return response.data;
};

export const getBulkDeleteProfiles = async ({ page, pageSize, queryParams = {} }) => {
    const queryString = Object.keys(queryParams)
        .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
        .join("&");
    const url = `/mailbot/bulk-delete/?${queryString}&limit=${pageSize}&offset=${(page - 1) * pageSize}`;
    const response = await backendServerAxiosInstance.get(url);
    return response.data;
};

export const getUnsubscribeProfiles = async ({ page, pageSize, queryParams = {} }) => {
    const queryString = Object.keys(queryParams)
        .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
        .join("&");
    let url = `/mailbot/unsubscribe-details/?${queryString}&limit=${pageSize}&offset=${(page - 1) * pageSize}`;
    const response = await backendServerAxiosInstance.get(url);
    return response.data;
};
