import { useQuery } from "@tanstack/react-query";
import { mailbotKeys } from "queryKeyFactory";
import {
    getCanMailBotDisabled,
    getCanMailBotEnabled,
    getIsMailBotActive,
    getMailBotProfile,
    getStatistics,
    getStatisticsForLatestSubscription,
    getHistoricalEmailsCount,
    getShowNewUIPopup,
    getShowNewPricingPlans,
    getShowOnboardingTour,
} from "services/mailbot/queries";

export function useMailBotProfile(select: any = undefined) {
    return useQuery({
        queryKey: mailbotKeys.mailbotProfile(),
        queryFn: getMailBotProfile,
        select: select,
    });
}

export function useMailBotPreferences() {
    return useMailBotProfile((data) => data.preferences);
}

export function useMailBotMetadata() {
    return useMailBotProfile((data) => data.metadata);
}

export function useIsPrimaryProfile() {
    return useMailBotProfile((data) => data.primary);
}

export function useMailBotServiceProvider() {
    return useMailBotProfile((data) => data.service_provider);
}

export function useMailBotStatistics() {
    return useQuery({
        staleTime: 0, // Always fetch the latest data for statistics as it is used in the dashboard
        queryKey: mailbotKeys.statistics(),
        queryFn: getStatistics,
    });
}

export function useCanMailBotDisabled() {
    return useQuery({
        queryKey: mailbotKeys.canDisable(),
        queryFn: getCanMailBotDisabled,
    });
}

export function useCanMailBotEnabled() {
    return useQuery({
        queryKey: mailbotKeys.canEnable(),
        queryFn: getCanMailBotEnabled,
    });
}

export function useIsMailBotActive() {
    return useQuery({
        queryKey: mailbotKeys.isActive(),
        queryFn: getIsMailBotActive,
    });
}

export function useHistoricalEmailsCount({ senderEmail, enabled }) {
    return useQuery({
        queryKey: mailbotKeys.historicalEmailsCount({ senderEmail }),
        queryFn: () => getHistoricalEmailsCount({ senderEmail }),
        enabled,
    });
}

export function useMailBotStatisticsForLatestSubscription({ enabled }) {
    return useQuery({
        staleTime: 0, // Always fetch the latest data for statistics as it is used in the dashboard
        queryKey: mailbotKeys.statisticsForLatestSubscription(),
        queryFn: getStatisticsForLatestSubscription,
        enabled: enabled,
    });
}

export function useShowNewUIPopup() {
    return useQuery({
        queryKey: mailbotKeys.showNewUIPopup(),
        queryFn: getShowNewUIPopup,
    });
}

export function useShowNewPricingPlans() {
    return useQuery({
        queryKey: mailbotKeys.showNewPricingPlans(),
        queryFn: getShowNewPricingPlans,
    });
}
export function useShowOnboardingTour() {
    return useQuery({
        queryKey: mailbotKeys.showOnboardingTour(),
        queryFn: getShowOnboardingTour,
    });
}
