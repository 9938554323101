import { Box, Tooltip, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { GridColDef } from "@mui/x-data-grid";
import { CustomRule } from "components/AutoCleaner/CustomRule";
import ZapMailButton from "components/AutoCleaner/ZapMailButton";
import ZappedText from "components/AutoCleaner/Zapped";
import DataTable from "components/Common/Data/DataTable";
import EmailDomainImage from "components/Common/EmailDomainImage";
import UndoButton from "components/Common/UndoButton";
import { UserAction, UserActionReason } from "defines";
import { useAutoCleanerProfiles } from "hooks/api/mailbot/paginatedQueries";
import { ISenderProfile } from "types/maintypes";
import { getDisplayNumber } from "utils/formatter";

function CustomRulesCell({ profile }: { profile: ISenderProfile }) {
    const workflowConfigured = !!profile.user_action && profile.user_action_reason === "rule_selected";
    const senderBlockedByEmailZap = !!profile.blocked_by_emailzap;
    const senderBlockedByUser = profile.user_training === "zapped";
    if (!workflowConfigured && !senderBlockedByEmailZap && !senderBlockedByUser) {
        return (
            <Grid container spacing={2}>
                <Grid size={6}>
                    <ZapMailButton profile={profile} />
                </Grid>
                <Grid size={6}>
                    <CustomRule profile={profile} ruleEnabled={false} />
                </Grid>
            </Grid>
        );
    } else if (senderBlockedByEmailZap) {
        return (
            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} height={"100%"}>
                <ZappedText title={"Zapped by EmailZap"} />
                <UndoButton undoTraining={true} trainingLabel="white_list" profile={profile} />
            </Box>
        );
    } else if (senderBlockedByUser) {
        return (
            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} height={"100%"}>
                <ZappedText title={"Zapped by you"} />
                <UndoButton undoTraining={true} trainingLabel="" profile={profile} />
            </Box>
        );
    } else {
        return <CustomRule profile={profile} ruleEnabled={true} />;
    }
}

function SenderEmailCell({ profile }) {
    return (
        <Box display={"flex"} alignItems={"center"} gap={"16px"} height={"100%"}>
            <EmailDomainImage email={profile.sender_email} domain={profile.sender_domain} name={profile.sender_name} />
            <Tooltip title={profile.sender_email}>
                <Typography noWrap>{profile.sender_email}</Typography>
            </Tooltip>
        </Box>
    );
}

const columns: GridColDef[] = [
    {
        field: "sender_email",
        headerName: "Sender's Email",
        flex: 2,
        headerClassName: "bg-white",
        sortingOrder: ["asc", "desc"],
        renderCell: (params) => {
            return <SenderEmailCell profile={params.row} />;
        },
    },
    {
        field: "total_count",
        headerName: "Total Emails",
        flex: 1,
        sortingOrder: ["asc", "desc"],
        headerClassName: "bg-white",
        resizable: false,
        valueFormatter: (value) => getDisplayNumber(value),
    },
    {
        field: "read_fraction",
        headerName: "Read By You (%)",
        flex: 1,
        sortingOrder: ["asc", "desc"],
        resizable: false,
        headerClassName: "bg-white",
        valueFormatter: (value) => (value * 100).toFixed(0) + "%",
    },
    {
        field: "custom_rules",
        headerName: "",
        align: "center",
        flex: 2,
        sortable: false,
        disableColumnMenu: true,
        headerClassName: "bg-white",
        resizable: false,
        renderCell: (params) => {
            return <CustomRulesCell profile={params.row} />;
        },
    },
];

export default function AutoCleanerTable() {
    return (
        <DataTable
            tabs={[
                {
                    label: "All Mails",
                    condition: [],
                    filters: [
                        { label: "Zapped by you", condition: [{ user_training: "zapped" }] },
                        {
                            label: "Marked as spam",
                            condition: [
                                {
                                    user_action: UserAction.MARK_AS_SPAM,
                                    user_action_reason: UserActionReason.RULE_SELECTED,
                                },
                            ],
                        },
                        {
                            label: "Marked as starred",
                            condition: [
                                {
                                    user_action: UserAction.MARK_AS_STARRED,
                                    user_action_reason: UserActionReason.RULE_SELECTED,
                                },
                            ],
                        },
                        {
                            label: "Moved to archive",
                            condition: [
                                { user_action: UserAction.ARCHIVE, user_action_reason: UserActionReason.RULE_SELECTED },
                            ],
                        },
                        {
                            label: "Marked as read",
                            condition: [
                                {
                                    user_action: UserAction.MARK_AS_READ,
                                    user_action_reason: UserActionReason.RULE_SELECTED,
                                },
                            ],
                        },
                    ],
                },
            ]}
            defaultOrdering={[{ field: "total_count", sort: "desc" }]}
            columns={columns}
            useData={useAutoCleanerProfiles}
            pageSize={20}
        />
    );
}
