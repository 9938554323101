import { useQuery } from "@tanstack/react-query";
import { mailbotKeys } from "queryKeyFactory";
import {
    getAutoCleanerProfiles,
    getBulkDeleteProfiles,
    getUnsubscribeProfiles,
} from "services/mailbot/paginatedQueries";

export function useAutoCleanerProfiles({ page, pageSize, queryParams = {} }) {
    const { data, isPending } = useQuery({
        queryKey: mailbotKeys.autoCleanerPage({ page, queryParams }),
        queryFn: () => getAutoCleanerProfiles({ page, pageSize, queryParams }),
    });
    return {
        data: data?.results,
        totalResults: data?.count || 0,
        isPending,
    };
}

export function useBulkDeleteProfiles({ page, pageSize, queryParams = {} }) {
    const { data, isPending } = useQuery({
        queryKey: mailbotKeys.bulkDeletePage({ page, queryParams }),
        queryFn: () => getBulkDeleteProfiles({ page, pageSize, queryParams }),
    });
    return {
        data: data?.results,
        totalResults: data?.count || 0,
        deleted_count: data?.deleted_count || 0,
        isPending,
    };
}

export function useSenderUnsubscribeDetails({ page, pageSize, queryParams = {} }) {
    const { data, isPending } = useQuery({
        queryKey: mailbotKeys.unsubscribePage({ page, queryParams }),
        queryFn: () => getUnsubscribeProfiles({ page, pageSize, queryParams }),
    });
    return {
        data: data?.results,
        totalResults: data?.count || 0,
        unsubscribed_count: data?.unsubscribed_count || 0,
        isPending,
    };
}
