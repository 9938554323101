import { Box, Button, Typography } from "@mui/material";
import { useMailBotStatisticsForLatestSubscription } from "hooks/api/mailbot/queries";
import { useLatestSubscription } from "hooks/api/payments/queries";
import { useCustomMediaQuery } from "hooks/useMediaQuery";
import { useNavigate } from "react-router-dom";

function ProgressBar({ value }) {
    return (
        <Box
            sx={{
                background: "#FF960033",
                height: "8px",
                borderRadius: "2px",
                width: "167px",
                padding: "1px",
            }}
        >
            <Box
                sx={{
                    width: `${value}%`,
                    background: "#FF9600",
                    height: "6px",
                    borderRadius: "2px",
                }}
            ></Box>
        </Box>
    );
}

export default function DeleteEmailsUsageMeter() {
    const { data: subscription, isPending } = useLatestSubscription();
    const { data: statisticsForLatestSubscription, isPending: statisticsForLatestSubscriptionPending } =
        useMailBotStatisticsForLatestSubscription({ enabled: !isPending && !!subscription });
    const navigate = useNavigate();
    const { md } = useCustomMediaQuery();
    if (isPending || statisticsForLatestSubscriptionPending) {
        return null;
    }
    const deleteEmailsLimit = subscription?.price.metadata["bulk_delete_limit"];
    if (!deleteEmailsLimit) {
        return null;
    }
    const priceNickname = subscription?.price.nickname;
    const deletedSendersCount = statisticsForLatestSubscription.senders_trashed || 0;
    return (
        <Box
            p={1}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            sx={{
                border: "1px solid #FF9600",
                borderRadius: "2px",
                background: "#FF96001A",
                width: "100%",
                maxWidth: md ? "304px" : "100%",
                height: "45px",
            }}
        >
            <Box>
                <Typography fontSize={"12px"} fontWeight={700}>
                    {priceNickname === "freebie" ? "Free plan" : "Basic plan"}
                </Typography>
                <Box display={"flex"} alignItems={"center"} gap={"4px"}>
                    <Typography
                        sx={{
                            fontSize: "14px",
                            color: "#0D0E23",
                        }}
                    >
                        {deletedSendersCount}/{deleteEmailsLimit}
                    </Typography>
                    <ProgressBar value={(deletedSendersCount / deleteEmailsLimit) * 100} />
                </Box>
            </Box>
            <Box>
                <Button
                    sx={{
                        background: "#0D0E23",
                        color: "white",
                        width: "67px",
                        fontSize: "12px",
                        borderRadius: "4px",
                    }}
                    data-testid="usage-meter"
                    onClick={() => {
                        navigate("/subscription");
                    }}
                    disableElevation
                    disableRipple
                >
                    Upgrade
                </Button>
            </Box>
        </Box>
    );
}
