import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { Box, Paper, Typography } from "@mui/material";
import ZappedText from "components/AutoCleaner/Zapped";
import DataCard from "components/Common/Data/DataCard";
import { UserActionReason } from "defines";
import { useSenderUnsubscribeDetails } from "hooks/api/mailbot/paginatedQueries";
import moment from "moment-timezone";
import { getDisplayNumber } from "utils/formatter";
import UnsubscribeButton from "./UnsubscribeButton";
import SubscriptionLimitDialog from "components/Common/Dialog/SubscriptionLimitDialog";
import { useState } from "react";

function UnsubscribeCardFooter({ profile, setSubscriptionLimitDialogOpen }) {
    if (profile.user_action_reason === UserActionReason.UNSUBSCRIBED) {
        return (
            <Box
                sx={{
                    background: "#F9FAFB",
                }}
                marginTop={"20px"}
                display={"flex"}
                py={"2px"}
                justifyContent={"center"}
            >
                <Typography fontSize={"10px"} color="#616265" fontWeight={500}>
                    Unsubscribed
                </Typography>
            </Box>
        );
    } else {
        const senderBlockedByEmailZap = profile.blocked_by_emailzap;
        const senderBlockedByUser = profile.user_training === "zapped";
        return senderBlockedByEmailZap || senderBlockedByUser ? (
            <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                height={"100%"}
                marginTop={"20px"}
            >
                <ZappedText title={senderBlockedByEmailZap ? "Zapped by EmailZap" : "Zapped by you"} />
                <UnsubscribeButton profile={profile} setSubscriptionLimitDialogOpen={setSubscriptionLimitDialogOpen} />
            </Box>
        ) : (
            <Box display={"flex"} justifyContent={"flex-end"} height={"100%"} alignItems={"center"} marginTop={"20px"}>
                <UnsubscribeButton profile={profile} setSubscriptionLimitDialogOpen={setSubscriptionLimitDialogOpen} />
            </Box>
        );
    }
}

function UnsubscribeCard({ profile, setSubscriptionLimitDialogOpen }) {
    return (
        <Paper
            variant="outlined"
            sx={{
                width: "100%",
                boxShadow: "0px 1px 1px 0px #0000001A",
                border: 0,
                borderRadius: 0,
                background: "white",
            }}
        >
            <Box p={1}>
                <Box mb={1} display={"flex"} alignItems={"center"} gap={"8px"} width={"90%"}>
                    <MailOutlineIcon
                        sx={{
                            color: "#111827",
                        }}
                    />
                    <Typography color="##111827" variant="subtitle2" fontWeight={500} lineHeight={"16.94px"} noWrap>
                        {profile.sender_email}
                    </Typography>
                </Box>
                <Box display={"flex"} gap={"8px"}>
                    <Typography color="#616265" variant="body2">
                        Mails: <strong>{getDisplayNumber(profile.total_count)}</strong>
                    </Typography>
                    <Typography color="#616265" variant="body2">
                        Last mail: <strong>{moment(profile.recent_timestamp).format("MMM DD, YYYY")}</strong>
                    </Typography>
                </Box>
                <UnsubscribeCardFooter
                    profile={profile}
                    setSubscriptionLimitDialogOpen={setSubscriptionLimitDialogOpen}
                />
            </Box>
        </Paper>
    );
}

export default function UnsubscribeCardList() {
    const [subscriptionLimitDialogOpen, setSubscriptionLimitDialogOpen] = useState(false);
    return (
        <>
            <SubscriptionLimitDialog
                open={subscriptionLimitDialogOpen}
                setOpen={setSubscriptionLimitDialogOpen}
                variant="unsubscribed"
            />
            <DataCard
                useData={useSenderUnsubscribeDetails}
                pageSize={20}
                ordering="-total_count,sender_email"
                renderCard={({ profile }) => (
                    <UnsubscribeCard
                        profile={profile}
                        setSubscriptionLimitDialogOpen={setSubscriptionLimitDialogOpen}
                    />
                )}
            />
        </>
    );
}
