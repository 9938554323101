import Loader from "components/Common/Loader";
import { useUser } from "hooks/api/accounts";
import { useIsMailBotFeatureEnabled } from "hooks/useChecks";
import useGoogleAnalytics from "hooks/useGoogleAnalytics";
import AppLayout from "layouts/AppLayout";
import JarvisLayout from "layouts/JarvisLayout";
import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import AutoCleaner from "screens/AutoCleaner";
import CheckoutCompletion from "screens/CheckoutCompletion";
import DeleteEmails from "screens/DeleteEmails";
import Home from "screens/Home";
import EditWorkflow from "screens/Jarvis/EditWorkflow";
import Workflow from "screens/Jarvis/Workflow";
import WorkflowList from "screens/Jarvis/WorkflowList";
import Login from "screens/Login";
import MailOperations from "screens/MailOperations";
import Subscription from "screens/Subscription";
import Unsubscriber from "screens/Unsubscriber";
import UserProfile from "screens/UserProfile";
import PostHogPageviewTracker from "./PostHogPageviewTracker";
import { MAILBOT_FEATURE_FLAGS } from "./defines";
import { useLatestSubscription } from "hooks/api/payments/queries";

function AuthenticatedRoutes() {
    const { enabled: unsubscribeEnabled, isPending: unsubscribePending } = useIsMailBotFeatureEnabled(
        MAILBOT_FEATURE_FLAGS.UNSUBSCRIBE,
    );
    const { enabled: bulkDeleteEnabled, isPending: bulkDeletePending } = useIsMailBotFeatureEnabled(
        MAILBOT_FEATURE_FLAGS.BULK_DELETE,
    );
    const { enabled: jarvisEnabled, isPending: jarvisPending } = useIsMailBotFeatureEnabled(
        MAILBOT_FEATURE_FLAGS.JARVIS,
    );
    const { data: subscription, isPending: subscriptionPending } = useLatestSubscription();
    useGoogleAnalytics();
    if (unsubscribePending || bulkDeletePending || jarvisPending || subscriptionPending) {
        return <Loader />;
    }
    return (
        <Routes>
            <Route path="/" element={<AppLayout isUserLoggedIn={true} />}>
                <Route index element={<Home />} />
                {subscription?.price.nickname !== "free_forever" && (
                    <Route path="subscription" element={<Subscription />} />
                )}
                <Route path="/profile" element={<UserProfile />} />
                <Route path="/mail-bot/auto-cleaner" element={<AutoCleaner />} />
                {bulkDeleteEnabled && <Route path="/mail-bot/delete-emails" element={<DeleteEmails />} />}
                {unsubscribeEnabled && <Route path="/mail-bot/unsubscribe" element={<Unsubscriber />} />}
                <Route path="/mail-operations" element={<MailOperations isUserLoggedIn={true} />} />
                <Route path="/completion" element={<CheckoutCompletion />} />
                {jarvisEnabled && (
                    <Route path="/workflows" element={<JarvisLayout />}>
                        <Route index element={<Workflow />} />
                        <Route path="create" element={<Workflow />} />
                        <Route path="list" element={<WorkflowList />} />
                        <Route path="edit" element={<EditWorkflow />} />
                    </Route>
                )}
                {/* Redirect to home if no route matches */}
                <Route path="*" element={<Navigate to="/" />} />
            </Route>
        </Routes>
    );
}

function DefaultRoutes() {
    return (
        <Routes>
            <Route path="/" element={<AppLayout isUserLoggedIn={false} />}>
                <Route index element={<Login />} />
                <Route path="/mail-operations" element={<MailOperations isUserLoggedIn={false} />} />
                <Route path="*" element={<Navigate to="/" />} />
            </Route>
        </Routes>
    );
}

export const AppRouter: React.FunctionComponent<{}> = () => {
    const { data, isPending, error } = useUser();

    if (isPending) {
        return <Loader />;
    }
    const isUserLoggedIn = !error && !!data;
    return (
        <BrowserRouter>
            <PostHogPageviewTracker />
            {isUserLoggedIn && <AuthenticatedRoutes />}
            {!isUserLoggedIn && <DefaultRoutes />}
        </BrowserRouter>
    );
};
