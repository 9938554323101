import { Box, Button, capitalize, Paper, Typography } from "@mui/material";
import CheckIcon from "components/Common/Icons/CheckIcon";
import Loader from "components/Common/Loader";
import { SubscriptionStatus } from "defines";
import { useCreateCheckoutSession } from "hooks/api/applications";
import { useCustomerPortalSession, useUpgradeSubscription } from "hooks/api/payments/mutations";
import { useLatestSubscription, usePricings } from "hooks/api/payments/queries";
import { useCustomMediaQuery } from "hooks/useMediaQuery";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import moment from "moment-timezone";

function CurrentPlanBadge() {
    return (
        <Box
            sx={{
                width: "116px",
                height: "30px",
                background: "#0D0E23",
                color: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "2px",
            }}
        >
            <Typography align="center">Current Plan</Typography>
        </Box>
    );
}

const PricingCard = ({ price, subscription, index }) => {
    const upgradeSubscription = useUpgradeSubscription();
    const createCheckoutSessionMutation = useCreateCheckoutSession();
    const { md } = useCustomMediaQuery();
    // Handle payments for the selected subscription plan
    const isSubscriptionActive =
        !!subscription &&
        [SubscriptionStatus.STATUS_ACTIVE, SubscriptionStatus.STATUS_PAST_DUE].includes(subscription.status);
    const handleCheckout = (data) => {
        createCheckoutSessionMutation.mutate(
            { data },
            {
                onSuccess: (checkoutSession) => {
                    window.location = checkoutSession.redirect_url;
                },
            },
        );
    };
    const onPricingPlanCardClick = ({ price, useCoupon = false, isTrial = false, trialPeriodDays = 0 }) => {
        if (isSubscriptionActive) {
            upgradeSubscription.mutate(
                { id: subscription.id, newPriceId: price.id },
                {
                    onSuccess: (checkoutSession) => {
                        window.location = checkoutSession.subscription_update_confirm_url;
                    },
                },
            );
        } else {
            handleCheckout({
                price: price.id,
                is_trial: isTrial,
                trial_period_days: trialPeriodDays,
                use_coupon: useCoupon,
            });
        }
    };
    let priceDescription = "",
        unsubscribeLimit,
        bulkDeleteLimit;
    let theme1 = {
        priceDescriptionColor: "#64748B",
        backgroundColor: "linear-gradient(180.69deg, #E2E5FD -130.74%, #FFFFFF 74.9%)",
        buttonBackground: "transparent",
        buttonTextColor: "#FF9600",
        buttonVariant: "outlined" as any,
        buttonBorderWidth: "2px",
    };
    let theme2 = {
        priceDescriptionColor: "#0D0E23",
        backgroundColor: "#EFBA42",
        buttonBackground: "#0D0E23",
        buttonTextColor: "white",
        buttonVariant: "contained" as any,
        buttonBorderWidth: "0px",
    };
    if (price.nickname === "freebie") {
        priceDescription = "Access to premium feature for a week.";
        unsubscribeLimit = "10 senders";
        bulkDeleteLimit = "10 senders";
    } else if (price.nickname === "basic") {
        priceDescription = "Monthly subscription with actions for upto 50 senders.";
        unsubscribeLimit = "50 senders";
        bulkDeleteLimit = "50 senders";
    } else if (price.nickname === "power") {
        priceDescription = "Annual plan with unlimited actions.";
        unsubscribeLimit = "Unlimited";
        bulkDeleteLimit = "Unlimited";
    }
    const currentPriceSubscribed = price.nickname === subscription?.price.nickname;
    return (
        <>
            <Paper
                sx={{
                    background: index % 2 === 0 ? theme1.backgroundColor : theme2.backgroundColor,
                    maxWidth: md ? "290px" : "343px",
                    width: "100%",
                    minHeight: md ? "478px" : "324px",
                }}
            >
                {price.is_current_plan && (
                    <Box display={"flex"} justifyContent={md ? "flex-start" : "flex-end"}>
                        <CurrentPlanBadge />
                    </Box>
                )}
                {currentPriceSubscribed && price.nickname === "freebie" && (
                    <Box display={"flex"} marginTop={"16px"} gap={"8px"} ml={"16px"}>
                        <ReportGmailerrorredIcon
                            sx={{
                                color: "#900B09",
                                fontSize: "30px",
                            }}
                        />
                        <Box>
                            <Typography variant="body2" sx={{ color: "#B3261E" }}>
                                Free subscription is ending on
                            </Typography>
                            <Typography fontWeight={700} variant="body2" sx={{ color: "#B3261E" }}>
                                {new Date(subscription?.current_period_end || "").toLocaleDateString("en-GB", {
                                    day: "numeric",
                                    month: "long",
                                    year: "numeric",
                                })}
                            </Typography>
                        </Box>
                    </Box>
                )}
                <Box
                    sx={{
                        px: "24px",
                        pt: price.is_current_plan ? "10px" : md ? "40px" : "20px",
                        pb: "40px",
                    }}
                >
                    <Box>
                        <Typography
                            gutterBottom
                            fontSize={md ? "22px" : "20px"}
                            fontWeight={700}
                            sx={{ color: "#0D0E23" }}
                        >
                            {capitalize(price.nickname)}
                        </Typography>
                        <Typography
                            variant={md ? "body1" : "body2"}
                            sx={{
                                color: index % 2 === 0 ? theme1.priceDescriptionColor : theme2.priceDescriptionColor,
                            }}
                        >
                            {priceDescription}
                        </Typography>
                    </Box>
                    <Box display={"flex"} alignItems={"center"} gap={"10px"} marginBottom={md ? "24px" : "12px"}>
                        <Typography fontWeight={600} fontSize={md ? "56px" : "44px"}>
                            ${price.unit_amount / 100}
                        </Typography>
                        <Typography fontWeight={300}>
                            {price.nickname === "freebie" ? `for 7 days` : `/ ${capitalize(price.interval)}`}
                        </Typography>
                    </Box>
                    {!currentPriceSubscribed && (
                        <Button
                            disableElevation
                            fullWidth
                            disableRipple
                            data-testid={`pricing-card-${price.nickname}`}
                            variant={index % 2 === 0 ? theme1.buttonVariant : theme2.buttonVariant}
                            sx={{
                                background: index % 2 === 0 ? theme1.buttonBackground : theme2.buttonBackground,
                                color: index % 2 === 0 ? theme1.buttonTextColor : theme2.buttonTextColor,
                                fontWeight: 600,
                                borderWidth: index % 2 === 0 ? theme1.buttonBorderWidth : theme2.buttonBorderWidth,
                                borderColor: index % 2 === 0 ? theme1.buttonTextColor : theme2.buttonTextColor,
                                marginBottom: "24px",
                            }}
                            onClick={() => {
                                onPricingPlanCardClick({ price });
                            }}
                        >
                            Subscribe
                        </Button>
                    )}
                    <Box marginTop={"16px"} display={"flex"} flexDirection={"column"} gap={"12px"}>
                        <Box display={"flex"} gap={"17px"}>
                            <Box>
                                <CheckIcon />
                            </Box>
                            <Typography variant={md ? "body1" : "body2"} fontWeight={500}>
                                Auto-Cleaner
                            </Typography>
                        </Box>
                        <Box display={"flex"} gap={"17px"}>
                            <Box>
                                <CheckIcon />
                            </Box>
                            <Typography fontWeight={500} variant={md ? "body1" : "body2"}>
                                Bulk Delete{" "}
                                <Typography
                                    fontWeight={500}
                                    component={"span"}
                                    display={"inline"}
                                    sx={{ color: "#4B5768" }}
                                    variant={md ? "body1" : "body2"}
                                >
                                    ({bulkDeleteLimit})
                                </Typography>
                            </Typography>
                        </Box>
                        <Box display={"flex"} gap={"17px"} alignItems={"center"}>
                            <Box>
                                <CheckIcon />
                            </Box>
                            <Typography lineHeight={"19.36px"} fontWeight={500} variant={md ? "body1" : "body2"}>
                                Bulk Unsubscribe{" "}
                                <Typography
                                    variant={md ? "body1" : "body2"}
                                    fontWeight={500}
                                    lineHeight={"19.36px"}
                                    display={"inline"}
                                    component={"span"}
                                    sx={{ color: "#4B5768" }}
                                >
                                    ({unsubscribeLimit})
                                </Typography>
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Paper>
            {(upgradeSubscription.isPending || createCheckoutSessionMutation.isPending) && <Loader />}
        </>
    );
};

const newPricingNicknames = ["freebie", "basic", "power"];

function ManagePaymentAndSubscription({ subscription, handleCancel, handleRenew }) {
    const customerPortalSessionMutation = useCustomerPortalSession();
    const { md } = useCustomMediaQuery();
    let subscriptionEndDate = new Date(subscription?.current_period_end || "").toLocaleDateString("en-GB", {
        day: "numeric",
        month: "long",
        year: "numeric",
    });
    let subscriptionData = {} as any;
    if (subscription?.cancel_at_period_end) {
        subscriptionData = {
            text: "Renew subscription",
            action: handleRenew,
            color: "green",
            daysRemainingMessage: "Subscription will expire on",
        };
    } else if (subscription?.status === SubscriptionStatus.STATUS_ACTIVE) {
        subscriptionData = {
            text: "Cancel Subscription",
            action: handleCancel,
            color: "#FF3B30",
            daysRemainingMessage: subscription?.cancel_at ? "Subscription ending on" : "Next billing date on",
        };
    } else if (SubscriptionStatus.STATUS_PAST_DUE === subscription.status) {
        subscriptionData = {
            daysRemainingMessage: "You will loose access if not paid till",
        };
        subscriptionEndDate = moment(subscription?.current_period_start).add(7, "days").format("DD MMMM YYYY");
    }
    return (
        <Box
            sx={{
                border: "1px solid #FF9600",
                background: "#FF96001A",
                borderRadius: "4px",
                p: 2,
                marginTop: "32px",
                display: "flex",
                flexDirection: md ? "row" : "column",
                justifyContent: "space-between",
                alignItems: md ? "center" : "flex-start",
                width: "100%",
            }}
        >
            <Box>
                <Typography gutterBottom fontWeight={600} fontSize={"20px"}>
                    Payment and subscription
                </Typography>
                <Typography fontSize={"14px"}>
                    {subscriptionData.daysRemainingMessage}: <strong>{subscriptionEndDate}</strong>
                </Typography>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "100%",
                    maxWidth: md ? "240px" : "295px",
                    marginTop: md ? 0 : "32px",
                }}
            >
                <Box
                    sx={{
                        width: "100%",
                    }}
                >
                    <Button
                        disableElevation
                        disableRipple
                        variant="contained"
                        fullWidth
                        onClick={() => {
                            customerPortalSessionMutation.mutate(undefined, {
                                onSuccess: (data) => {
                                    window.location = data.customer_portal_url;
                                },
                            });
                        }}
                        data-testid="manage-billing-button"
                        sx={{
                            background: "#0D0E23",
                            color: "white",
                            fontWeight: 600,
                            marginBottom: "10px",
                        }}
                    >
                        Manage Payment
                    </Button>
                </Box>
                {subscriptionData.action && (
                    <Box
                        sx={{
                            width: "100%",
                        }}
                    >
                        <Button
                            disableElevation
                            disableRipple
                            fullWidth
                            data-testid="subscription-action-button"
                            onClick={subscriptionData.action}
                            sx={{
                                color: subscriptionData.color,
                            }}
                            variant="text"
                        >
                            {subscriptionData.text}
                        </Button>
                    </Box>
                )}
            </Box>
        </Box>
    );
}

export default function PricingCatalogNew({ handleCancel, handleRenew }) {
    const { data: subscription, isPending: subscriptionPending } = useLatestSubscription();
    const { data: pricings, isPending: pricesPending } = usePricings();

    const { md } = useCustomMediaQuery();
    if (subscriptionPending || pricesPending) {
        return <Loader />;
    }
    return (
        <Box maxWidth={md ? "916px" : "343px"} width={"100%"}>
            <Box marginBottom={"32px"}>
                <Typography fontSize={md ? "20px" : "14px"} fontWeight={600} sx={{ color: "#2B333B" }}>
                    EmailZap Subscription
                </Typography>
                <Typography variant={md ? "body2" : "caption"}>
                    Choose your right plan for seamless email experience.
                </Typography>
            </Box>
            <Box display={"flex"} gap={"24px"} flexDirection={md ? "row" : "column"} justifyContent={"center"}>
                {pricings
                    .filter((p) => newPricingNicknames.includes(p.nickname))
                    .map((pricing, idx) => (
                        <PricingCard key={pricing.id} price={pricing} subscription={subscription} index={idx} />
                    ))}
            </Box>
            {!!subscription && (
                <ManagePaymentAndSubscription
                    handleCancel={handleCancel}
                    handleRenew={handleRenew}
                    subscription={subscription}
                />
            )}
        </Box>
    );
}
