import { backendServerAxiosInstance } from "services";

export const updateMailBotPreference = async (preference) => {
    const response = await backendServerAxiosInstance.put("/mailbot/profile/preferences/", { preference });
    return response.data;
};

export const deleteUserMailBotProfile = async () => {
    const response = await backendServerAxiosInstance.delete(`/mailbot/profile/me/`);
    return response.data;
};

export const updateUserTrainingStatus = async ({ profiles, trainingLabel }) => {
    const response = await backendServerAxiosInstance.post(`/mailbot/auto-cleaner/update-training/`, {
        sender_profiles: profiles,
        user_training: trainingLabel,
    });
    return response.data;
};

export const deactivateMailBotProfile = async () => {
    return updateMailBotPreference({ mailbot_enabled: false });
};

export const activateMailBotProfile = async () => {
    return updateMailBotPreference({ mailbot_enabled: true });
};

export const switchMailProfile = async (user_mailbot_profile_id) => {
    const response = await backendServerAxiosInstance.post("/mailbot/secondary-profiles/switch/", {
        user_profile_to_switch_id: user_mailbot_profile_id,
    });
    return response.data;
};
export const createCustomWorkflow = async ({ senderProfileId, action, actionReason }) => {
    const response = await backendServerAxiosInstance.post(
        `/mailbot/auto-cleaner/${senderProfileId}/custom-workflow/`,
        {
            action: action,
            action_reason: actionReason,
        },
    );
    return response.data;
};

export const deleteCustomWorkflow = async ({ senderProfileId }) => {
    const response = await backendServerAxiosInstance.delete(
        `/mailbot/auto-cleaner/${senderProfileId}/custom-workflow/`,
    );
    return response.data;
};

export const sendSupportEmail = async ({ text }) => {
    const response = await backendServerAxiosInstance.post(`/mailbot/support/`, { body: text });
    return response.data;
};

export const deleteHistoricalEmails = async ({ senderProfileId, action }) => {
    const response = await backendServerAxiosInstance.post(`/mailbot/bulk-delete/${senderProfileId}/trash/`, {
        action: action,
    });
    return response.data;
};

export const unsubscribeSender = async ({ senderProfileId }) => {
    const response = await backendServerAxiosInstance.post(
        `/mailbot/unsubscribe-details/${senderProfileId}/unsubscribe/`,
    );
    return response.data;
};

export const markNewUIViewed = async () => {
    const response = await backendServerAxiosInstance.post(`/mailbot/checks/new-ui-viewed/`);
    return response.data;
};

export const mailOperations = async ({ params }) => {
    const response = await backendServerAxiosInstance.post(`/mailbot/mail-operations/`, params);
    return response.data;
};

export const updateUserRole = async ({ userRole }) => {
    const response = await backendServerAxiosInstance.post(`/mailbot/profile/user-role/`, {
        user_role: userRole,
    });
    return response.data;
};

export const updateOnboardingTourViewed = async ({ latestScreenViewed }) => {
    const response = await backendServerAxiosInstance.post(`/mailbot/profile/onboarding-tour-viewed/`, {
        last_onboarding_tour_step_viewed: latestScreenViewed,
    });
    return response.data;
};
