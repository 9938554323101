import { Box, Button, Typography } from "@mui/material";
import AutoCleanerCardList from "components/AutoCleaner/AutoCleanerCard";
import AutoCleanerTable from "components/AutoCleaner/AutoCleanerTable";
import BuySubscription from "components/Common/BuySubscription";
import FeatureDetailedInfoCard from "components/Common/FeatureDetailedInfoCard";
import Loader from "components/Common/Loader";
import MobileTabs from "components/Common/MobileTabs";
import SubscriptionStrip from "components/Subscription/SubscriptionStrip";
import { maxContentWidthDesktop } from "defines";
import { useLatestSubscription } from "hooks/api/payments/queries";
import { useCustomMediaQuery } from "hooks/useMediaQuery";

const featureTitle = "How Auto-cleaner works";
const featureDescription = [
    `Auto cleaner analyses your past email behaviour and advanced algorithm to decide which email should land in your inbox`,
    `Any email which is Zapped, either by you or EmailZap, will get sent to the "-Zapped" label created in your mailbox`,
    `Undoing a Zapped email will lead to future emails from that sender being sent to your inbox`,
];

export default function AutoCleaner() {
    const { md } = useCustomMediaQuery();
    const { data: subscription, isPending } = useLatestSubscription();
    if (isPending) {
        return <Loader />;
    }
    return (
        <Box>
            {!md && <MobileTabs />}
            {md && <SubscriptionStrip />}
            <Box
                marginLeft={"auto"}
                marginRight={"auto"}
                marginBottom={"32px"}
                maxWidth={!md ? "576px" : maxContentWidthDesktop}
                p={2}
            >
                <Box marginBottom={md ? "26px" : "10px"}>
                    <Typography
                        variant={!md ? "subtitle2" : "h6"}
                        fontWeight={600}
                        lineHeight={"24.2px"}
                        gutterBottom
                        color="#2B333B"
                    >
                        Auto Cleaner
                    </Typography>
                    <Typography variant="subtitle2" lineHeight={"16.94px"} color="#2B333B">
                        Let EmailZap handle your inbox
                    </Typography>
                </Box>
                <Box marginBottom={"20px"}>
                    <FeatureDetailedInfoCard title={featureTitle} description={featureDescription} />
                </Box>
                {!subscription ? (
                    <BuySubscription title="You have not subscribed to any plan yet. Please subscribe to use Auto Cleaner." />
                ) : md ? (
                    <AutoCleanerTable />
                ) : (
                    <AutoCleanerCardList />
                )}
            </Box>
        </Box>
    );
}
