import { Box, Button, capitalize, Card, Dialog, Paper, Typography } from "@mui/material";
import CheckIcon from "components/Common/Icons/CheckIcon";
import Loader from "components/Common/Loader";
import { SubscriptionStatus } from "defines";
import { useUpgradeSubscription } from "hooks/api/payments/mutations";
import { useLatestSubscription, usePricings } from "hooks/api/payments/queries";
import { useCustomMediaQuery } from "hooks/useMediaQuery";

const PricingCard = ({ price, subscription, index, onContinue }) => {
    const upgradeSubscription = useUpgradeSubscription();
    const { md } = useCustomMediaQuery();
    // Handle payments for the selected subscription plan
    const isSubscriptionActive =
        !!subscription &&
        [SubscriptionStatus.STATUS_ACTIVE, SubscriptionStatus.STATUS_PAST_DUE].includes(subscription.status);
    const onPricingPlanCardClick = ({ price }) => {
        if (isSubscriptionActive && subscription?.price?.nickname.toLowerCase() !== "free") {
            upgradeSubscription.mutate(
                { id: subscription.id, newPriceId: price.id },
                {
                    onSuccess: (checkoutSession) => {
                        window.location = checkoutSession.subscription_update_confirm_url;
                    },
                },
            );
        }
    };
    let priceDescription = "",
        unsubscribeLimit,
        bulkDeleteLimit;
    let theme1 = {
        priceDescriptionColor: "#64748B",
        backgroundColor: "linear-gradient(180.69deg, #E2E5FD -130.74%, #FFFFFF 74.9%)",
        buttonBackground: "transparent",
        buttonTextColor: "#FF9600",
        buttonVariant: "outlined" as any,
        buttonBorderWidth: "2px",
        boxShadow: "",
    };
    let theme2 = {
        priceDescriptionColor: "#0D0E23",
        backgroundColor: "#EFBA42",
        buttonBackground: "#0D0E23",
        buttonTextColor: "white",
        buttonVariant: "contained" as any,
        buttonBorderWidth: "0px",
        boxShadow: "0px 10px 25px 0px #FFEB9A80",
    };
    if (price.nickname === "freebie") {
        priceDescription = "Access to premium feature for a week.";
        unsubscribeLimit = "10 senders";
        bulkDeleteLimit = "10 senders";
    } else if (price.nickname === "basic") {
        priceDescription = "Monthly subscription with actions for upto 50 senders.";
        unsubscribeLimit = "50 senders";
        bulkDeleteLimit = "50 senders";
    } else if (price.nickname === "power") {
        priceDescription = "Annual plan with unlimited actions.";
        unsubscribeLimit = "Unlimited";
        bulkDeleteLimit = "Unlimited";
    }
    const currentPriceSubscribed = price.nickname === subscription?.price.nickname;
    return (
        <>
            <Box
                sx={{
                    background: index % 2 === 0 ? theme1.backgroundColor : theme2.backgroundColor,
                    boxShadow: index % 2 === 0 ? theme1.boxShadow : theme2.boxShadow,
                    maxWidth: md ? "290px" : "390px",
                    width: "100%",
                    minHeight: md ? "432px" : "324px",
                    borderRadius: "12px",
                }}
            >
                <Box
                    sx={{
                        px: "24px",
                        pt: "10px",
                        pb: "40px",
                    }}
                >
                    <Box>
                        <Typography
                            gutterBottom
                            fontSize={md ? "22px" : "20px"}
                            fontWeight={700}
                            sx={{ color: "#0D0E23" }}
                        >
                            {capitalize(price.nickname)}
                        </Typography>
                        <Typography
                            variant={md ? "body1" : "body2"}
                            sx={{
                                color: index % 2 === 0 ? theme1.priceDescriptionColor : theme2.priceDescriptionColor,
                            }}
                        >
                            {priceDescription}
                        </Typography>
                    </Box>
                    <Box display={"flex"} alignItems={"center"} gap={"10px"} marginBottom={md ? "24px" : "12px"}>
                        <Typography fontWeight={600} fontSize={md ? "56px" : "44px"}>
                            ${price.unit_amount / 100}
                        </Typography>
                        <Typography fontWeight={300}>
                            {price.nickname === "freebie" ? `for 7 days` : `/ ${capitalize(price.interval)}`}
                        </Typography>
                    </Box>
                    <Button
                        disableElevation
                        fullWidth
                        disableRipple
                        data-testid={`pricing-card-${price.nickname}`}
                        variant={index % 2 === 0 ? theme1.buttonVariant : theme2.buttonVariant}
                        sx={{
                            background: index % 2 === 0 ? theme1.buttonBackground : theme2.buttonBackground,
                            color: index % 2 === 0 ? theme1.buttonTextColor : theme2.buttonTextColor,
                            fontWeight: 600,
                            borderWidth: index % 2 === 0 ? theme1.buttonBorderWidth : theme2.buttonBorderWidth,
                            borderColor: index % 2 === 0 ? theme1.buttonTextColor : theme2.buttonTextColor,
                            marginBottom: "24px",
                        }}
                        onClick={() => {
                            if (currentPriceSubscribed) {
                                onContinue();
                            } else {
                                onPricingPlanCardClick({ price });
                            }
                        }}
                    >
                        {currentPriceSubscribed ? "Continue" : "Upgrade"}
                    </Button>
                    <Box marginTop={"16px"} display={"flex"} flexDirection={"column"} gap={"12px"}>
                        <Box display={"flex"} gap={"17px"}>
                            <Box>
                                <CheckIcon />
                            </Box>
                            <Typography variant={md ? "body1" : "body2"} fontWeight={500}>
                                Auto-Cleaner
                            </Typography>
                        </Box>
                        <Box display={"flex"} gap={"17px"}>
                            <Box>
                                <CheckIcon />
                            </Box>
                            <Typography fontWeight={500} variant={md ? "body1" : "body2"}>
                                Bulk Delete{" "}
                                <Typography
                                    fontWeight={500}
                                    component={"span"}
                                    display={"inline"}
                                    sx={{ color: "#4B5768" }}
                                    variant={md ? "body1" : "body2"}
                                >
                                    ({bulkDeleteLimit})
                                </Typography>
                            </Typography>
                        </Box>
                        <Box display={"flex"} gap={"17px"} alignItems={"center"}>
                            <Box>
                                <CheckIcon />
                            </Box>
                            <Typography lineHeight={"19.36px"} fontWeight={500} variant={md ? "body1" : "body2"}>
                                Bulk Unsubscribe{" "}
                                <Typography
                                    variant={md ? "body1" : "body2"}
                                    fontWeight={500}
                                    lineHeight={"19.36px"}
                                    display={"inline"}
                                    component={"span"}
                                    sx={{ color: "#4B5768" }}
                                >
                                    ({unsubscribeLimit})
                                </Typography>
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
            {upgradeSubscription.isPending && <Loader />}
        </>
    );
};

const newPricingNicknames = ["freebie", "basic", "power"];

export default function SubscriptionSetup({ open, onNext }) {
    const { md } = useCustomMediaQuery();
    const { data: subscription, isPending: subscriptionPending } = useLatestSubscription();
    const { data: pricings, isPending: pricesPending } = usePricings();
    if (subscriptionPending || pricesPending) {
        return <Loader />;
    }
    return (
        <Dialog
            open={open}
            fullScreen={!md}
            sx={{
                backdropFilter: "blur(4px)",
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: md ? "988px" : "100%",
                        height: md ? "732px" : "100%",
                        boxShadow: md
                            ? "0px 1px 40px 0px #FF9600 inset; 0px 4px 18px 0px #FF9600 inset"
                            : "0px 1px 20px 0px #FF9600 inset; 0px 0px 10px 0px #FF9600 inset",
                        borderRadius: md ? "20px" : 0,
                        padding: md ? "80px 40px 40px 40px" : "20px",
                    },
                },
                background:
                    "linear-gradient(108.46deg, rgba(255, 255, 255, 0.264) 0%, rgba(255, 255, 255, 0.066) 100%)",
            }}
        >
            <Box>
                <Box marginBottom={"36px"}>
                    <Typography
                        fontSize={"20px"}
                        lineHeight={md ? "inherit" : "24.2px"}
                        fontWeight={600}
                        sx={{ color: "#1F2337" }}
                        gutterBottom
                    >
                        You're almost there! Want to upgrade your subscription before checking your emails?
                    </Typography>
                    <Typography fontWeight={400} fontSize={"14px"} sx={{ color: "#1C1D31" }}>
                        Each feature empowers you to keep an effortlessly organized inbox, so you can focus on what
                        truly matters.
                    </Typography>
                </Box>
                <Box
                    display={"flex"}
                    gap={"24px"}
                    flexDirection={md ? "row" : "column"}
                    justifyContent={"center"}
                    alignItems={"center"}
                >
                    {pricings
                        .filter((p) => newPricingNicknames.includes(p.nickname))
                        .map((pricing, idx) => (
                            <PricingCard
                                key={pricing.id}
                                price={pricing}
                                subscription={subscription}
                                index={idx}
                                onContinue={onNext}
                            />
                        ))}
                </Box>
            </Box>
        </Dialog>
    );
}
