import { Box, Button, Dialog, Divider, Grid2, Typography } from "@mui/material";
import MailInfoIcon from "components/Common/Icons/MailInfoIcon";
import UnsubscribeIcon from "components/Common/Icons/UnsubscribeIcon";
import { useCustomMediaQuery } from "hooks/useMediaQuery";
import MailOutlineIcon from "@mui/icons-material/MailOutline";

function ClickIcon() {
    return (
        <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.333008 10.5V8.16667H3.83301V10.5H0.333008ZM4.29967 16.975L2.66634 15.2833L5.11634 12.8333L6.80801 14.4667L4.29967 16.975ZM5.11634 5.83333L2.66634 3.38333L4.29967 1.69167L6.80801 4.2L5.11634 5.83333ZM18.9997 19.8333L13.458 14.2917L11.9997 18.6667L8.49967 7L20.1663 10.5L15.8497 12.0167L21.333 17.5L18.9997 19.8333ZM9.66634 3.5V0H11.9997V3.5H9.66634ZM16.5497 5.83333L14.858 4.2L17.3663 1.69167L18.9997 3.325L16.5497 5.83333Z"
                fill="#080808"
            />
        </svg>
    );
}

function UnsubscriberIcon() {
    return (
        <svg width="88" height="90" viewBox="0 0 88 90" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.5 1.35156H75.053V75.9045H0.5V1.35156Z" stroke="#FF9600" />
            <path d="M12.4922 14.0938H87.0452V88.6467H12.4922V14.0938Z" stroke="#FF9600" />
            <path d="M5.99609 6.84766H81.5491V82.4006H5.99609V6.84766Z" fill="#EFBA42" />
            <path
                d="M59.3632 66.1348H28.5574C28.0327 66.1348 27.583 66.5845 27.583 67.1091C27.583 67.6338 28.0327 68.0836 28.5574 68.0836H59.3632C59.8879 68.0836 60.3376 67.6338 60.3376 67.1091C60.3376 66.5845 59.8879 66.1348 59.3632 66.1348Z"
                fill="black"
            />
            <path
                d="M67.0836 61.4138C67.0836 61.3388 67.0836 30.533 67.0836 30.3831C67.0836 30.2332 67.0087 30.0833 66.9337 29.9334L66.8588 29.8584L66.7838 29.7835L66.7088 29.7085L66.6339 29.6336C66.6339 29.6336 66.559 29.6335 66.559 29.5586H66.484H66.4091C66.2592 29.5586 66.9337 29.5586 55.6158 29.5586C55.0911 29.5586 54.6414 30.0083 54.6414 30.533C54.6414 31.0577 55.0911 31.5074 55.6158 31.5074H63.4109L44.073 46.648L24.66 31.5074H32.4552C32.9799 31.5074 33.4296 31.0577 33.4296 30.533C33.4296 30.0083 32.9799 29.5586 32.4552 29.5586C21.1372 29.5586 21.8118 29.5586 21.6619 29.5586H21.587H21.512C21.512 29.5586 21.4371 29.5586 21.4371 29.6336L21.3621 29.7085L21.2871 29.7835L21.2122 29.8584L21.1372 29.9334C20.9873 30.0833 20.9873 30.3081 20.9873 30.458C20.9873 30.458 20.9873 61.2639 20.9873 61.4138C20.9873 61.4887 20.9873 61.4887 20.9873 61.4887C20.9873 61.5637 20.9873 61.5637 20.9873 61.5637C20.9873 61.6386 20.9873 61.6386 20.9873 61.6386C20.9873 61.7136 20.9873 61.7136 21.0623 61.7136C21.0623 61.7136 21.0623 61.7136 21.1372 61.7885C21.2871 62.0134 21.5869 62.1633 21.8868 62.1633C67.6083 62.1633 66.2591 62.1633 66.3341 62.1633C66.7088 62.2383 67.0087 61.8635 67.0836 61.4138ZM44.5976 48.5968L65.1348 32.4818V59.3151L52.2429 49.0465C51.7931 48.7467 51.1935 48.7467 50.8937 49.1964C50.5939 49.5711 50.5939 50.2457 51.0436 50.5455L63.4109 60.3644H24.5101L36.8774 50.5455C37.2522 50.2457 37.3272 49.6461 37.0273 49.1964C36.7275 48.8216 36.1279 48.7467 35.6782 49.0465L22.7862 59.3151V32.4818L43.3984 48.5968C43.6982 48.8966 44.2228 48.8966 44.5976 48.5968Z"
                fill="black"
            />
            <path d="M35.3037 56.541H37.1775V58.4148H35.3037V56.541Z" fill="black" />
            <path d="M39.126 56.541H40.9998V58.4148H39.126V56.541Z" fill="black" />
            <path d="M42.9482 56.541H44.897V58.4148H42.9482V56.541Z" fill="black" />
            <path d="M46.8467 56.541H48.7205V58.4148H46.8467V56.541Z" fill="black" />
            <path d="M50.6689 56.541H52.5428V58.4148H50.6689V56.541Z" fill="black" />
            <path
                d="M43.9983 39.2271C48.7203 39.2271 52.6179 35.3295 52.6179 30.5325C52.6179 25.6605 48.7953 21.8379 43.9983 21.8379C39.1263 21.8379 35.3037 25.7355 35.3037 30.5325C35.3037 35.2545 39.2013 39.2271 43.9983 39.2271ZM43.9983 23.7867C47.671 23.7867 50.6691 26.7848 50.6691 30.5325C50.6691 34.2052 47.671 37.2783 43.9983 37.2783C40.2506 37.2783 37.2525 34.2052 37.2525 30.5325C37.2525 26.7848 40.2506 23.7867 43.9983 23.7867Z"
                fill="black"
            />
            <path
                d="M40.1004 31.5074H47.8206C48.3453 31.5074 48.795 31.0577 48.795 30.533C48.795 30.0083 48.3453 29.5586 47.8206 29.5586H40.1004C39.5757 29.5586 39.126 30.0083 39.126 30.533C39.126 31.0577 39.5757 31.5074 40.1004 31.5074Z"
                fill="black"
            />
        </svg>
    );
}

function UnsubscriberTablePreview() {
    return (
        <Box
            sx={{
                boxShadow: "0px 0px 16px 0px #00000024",
            }}
        >
            <Grid2 container padding={"10px"} alignItems={"center"}>
                <Grid2 size={3}>
                    <Typography fontSize={"12px"} fontWeight={400}>
                        Sender’s email
                    </Typography>
                </Grid2>
                <Grid2 size={2}>
                    <Typography fontSize={"12px"} fontWeight={400}>
                        Emails received
                    </Typography>
                </Grid2>
                <Grid2 size={2}>
                    <Typography fontSize={"12px"} fontWeight={400}>
                        Last email received
                    </Typography>
                </Grid2>
            </Grid2>
            <Grid2
                container
                alignItems={"center"}
                padding={"10px"}
                sx={{
                    background: "#F9FAFB",
                }}
            >
                <Grid2 size={3}>
                    <Typography fontWeight={500} fontSize={"14px"}>
                        hello@doordash.com
                    </Typography>
                </Grid2>
                <Grid2 size={2}>
                    <Typography fontWeight={500} fontSize={"14px"}>
                        421
                    </Typography>
                </Grid2>
                <Grid2 size={2}>
                    <Typography fontWeight={500} fontSize={"14px"}>
                        5 hrs ago
                    </Typography>
                </Grid2>
                <Grid2 size={5}>
                    <Box display={"flex"} gap={"32px"} alignItems={"center"} justifyContent={"flex-end"}>
                        <Box>
                            <Box display={"flex"} justifyContent="center" alignItems="center" gap={"4px"}>
                                <MailInfoIcon size={20} color={"black"} />

                                <Typography fontSize={"12px"} fontWeight={500} lineHeight={"14.52px"} color={"black"}>
                                    Zapped by EmailZap
                                </Typography>
                            </Box>
                        </Box>
                        <Box>
                            <Button
                                disableElevation
                                disableRipple
                                sx={{
                                    background: "rgba(239, 186, 66, 0.1)",
                                    border: "1px solid #EFBA42",
                                    borderRadius: "4px",
                                    width: "132px",
                                    height: "40px",
                                }}
                                variant="contained"
                                startIcon={<UnsubscribeIcon size={20} />}
                            >
                                <Typography color="black" variant={"body2"}>
                                    Unsubscribe
                                </Typography>
                            </Button>
                        </Box>
                    </Box>
                </Grid2>
            </Grid2>
        </Box>
    );
}

function UnsubscriberCardPreview() {
    return (
        <Box>
            <Divider
                sx={{
                    border: "1px solid #E6E6E6",
                }}
            />
            <Box
                sx={{
                    width: "100%",
                    boxShadow: "0px 1px 1px 0px #0000001A",
                    border: 0,
                    borderRadius: 0,
                    background: "white",
                }}
            >
                <Box p={1}>
                    <Box display={"flex"} alignItems={"center"} gap={"8px"} marginBottom={"10px"}>
                        <MailOutlineIcon
                            sx={{
                                color: "#111827",
                            }}
                        />
                        <Typography color="##111827" variant="subtitle2" fontWeight={500} lineHeight={"16.94px"} noWrap>
                            hello@doordash.com
                        </Typography>
                    </Box>
                    <Box display={"flex"} gap={"8px"} marginBottom={"20px"}>
                        <Typography color="#616265" variant="body2">
                            Mails: <strong>2,433</strong>
                        </Typography>
                        <Typography color="#616265" variant="body2">
                            Read: <strong>20%</strong>
                        </Typography>
                    </Box>
                    <Box display={"flex"} gap={"18px"} justifyContent={"space-between"} alignItems={"center"}>
                        <Box display={"flex"} justifyContent="center" alignItems="center" gap={"4px"}>
                            <MailInfoIcon size={20} color={"black"} />
                            <Typography fontSize={"12px"} fontWeight={500} lineHeight={"14.52px"} color={"black"}>
                                Zapped by you
                            </Typography>
                        </Box>
                        <Button
                            disableElevation
                            disableRipple
                            sx={{
                                background: "rgba(239, 186, 66, 0.1)",
                                borderRadius: "4px",
                                width: "100px",
                                height: "30px",
                            }}
                            variant="contained"
                            startIcon={<UnsubscribeIcon size={16} />}
                        >
                            <Typography color="#1B1301" variant={"caption"}>
                                Unsubscribe
                            </Typography>
                            <Box
                                sx={{
                                    position: "absolute",
                                    bottom: -10,
                                    right: 10,
                                }}
                            >
                                <ClickIcon />
                            </Box>
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export default function UnsubscriberPreview({ open, onNext }) {
    const { md } = useCustomMediaQuery();
    return (
        <Dialog
            open={open}
            fullScreen={!md}
            sx={{
                backdropFilter: "blur(4px)",
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: md ? "988px" : "100%",
                        maxHeight: md ? "732px" : "none",
                        boxShadow: md
                            ? "0px 1px 40px 0px #FF9600 inset; 0px 4px 18px 0px #FF9600 inset"
                            : "0px 1px 20px 0px #FF9600 inset; 0px 0px 10px 0px #FF9600 inset",
                        borderRadius: md ? "20px" : 0,
                        padding: md ? "80px 40px 40px 40px" : "20px",
                    },
                },
                background:
                    "linear-gradient(108.46deg, rgba(255, 255, 255, 0.264) 0%, rgba(255, 255, 255, 0.066) 100%)",
            }}
        >
            <Box display={"flex"} flexDirection={"column"} gap={"32px"} height={"max(100%, 775px)"}>
                <Box>
                    <Box>
                        <Typography fontSize={md ? "20px" : "18px"} fontWeight={600} sx={{ color: "#1F2337" }}>
                            EmailZap welcomes you to your CLEANEST inbox yet
                        </Typography>
                        <Typography fontWeight={400} fontSize={"14px"} sx={{ color: "#1C1D31" }}>
                            Say goodbye to clutter and distractions—these powerful new tools are here to keep your inbox
                            focused on what matters most. Explore the new features below, designed to enhance your email
                            experience and boost productivity.
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            marginTop: md ? "60px" : "20px",
                            padding: md ? "16px 40.28px 16px 24px" : "4px",
                            borderRadius: "8px",
                            border: "1px solid  #EFBA42",
                            boxShadow: "0px 20px 47px 0px #0000000D",
                        }}
                    >
                        <Box
                            display={"flex"}
                            gap={"16px"}
                            alignItems={"center"}
                            marginBottom={"16px"}
                            flexDirection={md ? "row" : "column"}
                        >
                            <Box>
                                <UnsubscriberIcon />
                            </Box>
                            <Box>
                                <Typography
                                    fontSize={"20px"}
                                    fontWeight={600}
                                    gutterBottom
                                    align={md ? "inherit" : "center"}
                                    variant={md ? "body1" : "body2"}
                                >
                                    Unsubscribe
                                </Typography>
                                <Typography align={md ? "inherit" : "center"} variant={md ? "body1" : "body2"}>
                                    <Typography
                                        display={"inline"}
                                        component={"span"}
                                        fontWeight={500}
                                        variant={md ? "body1" : "body2"}
                                    >
                                        One-Click Peace of Mind.{" "}
                                    </Typography>
                                    Easily unsubscribe from all mailing lists. Not only that. if they try to spam you
                                    again, their emails will be auto-sent to Trash
                                </Typography>
                            </Box>
                        </Box>
                        {md ? <UnsubscriberTablePreview /> : <UnsubscriberCardPreview />}
                    </Box>
                </Box>
                <Box marginTop={"auto"}>
                    <Button
                        variant="contained"
                        fullWidth
                        disableElevation
                        disableRipple
                        onClick={onNext}
                        sx={{
                            height: "41px",
                            color: "black",
                            fontWeight: 600,
                            fontSize: "14px",
                        }}
                    >
                        Next
                    </Button>
                </Box>
            </Box>
        </Dialog>
    );
}
