import { Button, Typography } from "@mui/material";
import UnsubscribeIcon from "components/Common/Icons/UnsubscribeIcon";
import Loader from "components/Common/Loader";
import { useUnsubscribeSender } from "hooks/api/mailbot/mutations";
import { useIsMailBotActive } from "hooks/api/mailbot/queries";
import { useCustomMediaQuery } from "hooks/useMediaQuery";
import { SnackbarMessageContext } from "layouts/AppLayout";
import { useContext, useState } from "react";
import UnsubscribeConfirmDialog from "./UnsubscribeConfirmModal";

export default function UnsubscribeButton({ profile, setSubscriptionLimitDialogOpen }) {
    const unsubscribeSenderMutation = useUnsubscribeSender();
    const { data: mailBotEnabled, isPending: mailBotEnabledPending } = useIsMailBotActive();
    const [open, setOpen] = useState(false);
    const { setSuccessMessage, setSuccessMessageTitle } = useContext(SnackbarMessageContext);
    const { md } = useCustomMediaQuery();
    if (mailBotEnabledPending || unsubscribeSenderMutation.isPending) {
        return <Loader />;
    }
    const handleUnsubscribe = () => {
        unsubscribeSenderMutation.mutate(
            { senderProfileId: profile.id },
            {
                onSuccess: () => {
                    setSuccessMessageTitle("Unsubscribed successfully");
                    setSuccessMessage(
                        "Unsubscribe request sent and if they try to mail you, their emails will be automatically moved to trash",
                    );
                },
                onError: (e: any) => {
                    if (e.status === 402) {
                        setSubscriptionLimitDialogOpen(true);
                    }
                },
            },
        );
    };

    const disabled = !mailBotEnabled;
    return (
        <>
            <Button
                onClick={() => {
                    setOpen(true);
                }}
                data-testid={`unsubscribe-${profile.sender_email}`}
                disabled={disabled}
                disableElevation
                sx={{
                    background: "rgba(239, 186, 66, 0.1)",
                    border: !disabled ? "1px solid #EFBA42" : "",
                    borderRadius: "4px",
                    width: md ? "132px" : "112px",
                    height: md ? "40px" : "30px",
                    "&:hover": {
                        background: "#FFAE02",
                    },
                }}
                variant="contained"
                startIcon={<UnsubscribeIcon size={md ? 20 : 16} />}
            >
                <Typography color="black" variant={md ? "body2" : "caption"}>
                    Unsubscribe
                </Typography>
            </Button>
            <UnsubscribeConfirmDialog open={open} setOpen={setOpen} handleUnsubscribe={handleUnsubscribe} />
        </>
    );
}
