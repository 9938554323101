import { backendServerAxiosInstance } from "services";

export const getStatistics = async () => {
    const response = await backendServerAxiosInstance.get("/mailbot/statistics/all/");
    return response.data;
};

export const getIsMailBotActive = async () => {
    const response = await backendServerAxiosInstance.get(`/mailbot/checks/is-active/`);
    return response.data;
};

export const getCanMailBotEnabled = async () => {
    const response = await backendServerAxiosInstance.get(`/mailbot/checks/can-enable/`);
    return response.data;
};

export const getCanMailBotDisabled = async () => {
    const response = await backendServerAxiosInstance.get(`/mailbot/checks/can-disable/`);
    return response.data;
};

export const getMailBotProfile = async () => {
    const response = await backendServerAxiosInstance.get("/mailbot/profile/me/");
    return response.data;
};

export const getMailSecondaryProfiles = async () => {
    const response = await backendServerAxiosInstance.get("/mailbot/secondary-profiles/");
    return response.data;
};

export const getHistoricalEmailsCount = async ({ senderEmail }) => {
    const response = await backendServerAxiosInstance.get(`/mailbot/bulk-delete/count/?sender_email=${senderEmail}`);
    return response.data;
};

export const getStatisticsForLatestSubscription = async () => {
    const response = await backendServerAxiosInstance.get("/mailbot/statistics/latest-subscription/");
    return response.data;
};

export const getShowNewUIPopup = async () => {
    const response = await backendServerAxiosInstance.get(`/mailbot/checks/show-new-ui-popup/`);
    return response.data;
};

export const getShowNewPricingPlans = async () => {
    const response = await backendServerAxiosInstance.get(`/mailbot/checks/show-new-pricing-plans/`);
    return response.data;
};

export const getShowOnboardingTour = async () => {
    const response = await backendServerAxiosInstance.get(`/mailbot/checks/show-onboarding-tour/`);
    return response.data;
};
