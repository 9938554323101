import { Box, Typography } from "@mui/material";
import BuySubscription from "components/Common/BuySubscription";
import FeatureDetailedInfoCard from "components/Common/FeatureDetailedInfoCard";
import Loader from "components/Common/Loader";
import MobileTabs from "components/Common/MobileTabs";
import SubscriptionStrip from "components/Subscription/SubscriptionStrip";
import UnsubscribeCardList from "components/Unusbscribe/UnsubscribeCard";
import UnsubscriberUsageMeter from "components/Unusbscribe/UnsubscriberUsageMeter";
import UnsubscribeTable from "components/Unusbscribe/UnsubscribeTable";
import { maxContentWidthDesktop } from "defines";
import { useLatestSubscription } from "hooks/api/payments/queries";
import { useCustomMediaQuery } from "hooks/useMediaQuery";

const featureTitle = "How unsubscribe works";
const featureDescription = [
    `When you hit unsubscribe, EmailZap will send an email request to the sender to unsubscribe you from mailing list`,
    `Additionally, EmailZap will ensure that all future emails from an unsubscribed sender get automatically deleted`,
    `There are certain senders that require you to go their webpage to unsubscribe. Those senders will not show up here`,
];

export default function Unsubscriber() {
    const { md } = useCustomMediaQuery();
    const { data: subscription, isPending } = useLatestSubscription();
    if (isPending) {
        return <Loader />;
    }
    return (
        <Box>
            {!md && <MobileTabs />}
            {md && <SubscriptionStrip />}
            <Box
                marginLeft={"auto"}
                marginRight={"auto"}
                marginBottom={"32px"}
                maxWidth={!md ? "576px" : maxContentWidthDesktop}
                p={2}
            >
                <Box display={"flex"} justifyContent={"space-between"} alignItems={"flex-start"}>
                    <Box marginBottom={md ? "26px" : "10px"}>
                        <Typography
                            variant={!md ? "subtitle2" : "h6"}
                            fontWeight={600}
                            lineHeight={"24.2px"}
                            gutterBottom
                            color="#2B333B"
                        >
                            Unsubscribe
                        </Typography>
                        <Typography variant="subtitle2" lineHeight={"16.94px"} color="#2B333B">
                            Unsubscribe from all mailing lists in one go
                        </Typography>
                    </Box>
                    {md && <UnsubscriberUsageMeter />}
                </Box>
                <Box marginBottom={"20px"}>
                    <FeatureDetailedInfoCard title={featureTitle} description={featureDescription} />
                </Box>
                {!subscription ? (
                    <BuySubscription title="You have not subscribed to any plan yet. Please subscribe to use Unsubscribe." />
                ) : md ? (
                    <UnsubscribeTable />
                ) : (
                    <UnsubscribeCardList />
                )}
            </Box>
        </Box>
    );
}
