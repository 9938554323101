import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { Box, Button, Dialog, Divider, Grid2, Typography } from "@mui/material";
import EditIcon from "components/Common/Icons/EditIcon";
import ZapSenderIcon from "components/Common/Icons/ZapSenderIcon";
import { useCustomMediaQuery } from "hooks/useMediaQuery";

function ClickIcon() {
    return (
        <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.333008 10.5V8.16667H3.83301V10.5H0.333008ZM4.29967 16.975L2.66634 15.2833L5.11634 12.8333L6.80801 14.4667L4.29967 16.975ZM5.11634 5.83333L2.66634 3.38333L4.29967 1.69167L6.80801 4.2L5.11634 5.83333ZM18.9997 19.8333L13.458 14.2917L11.9997 18.6667L8.49967 7L20.1663 10.5L15.8497 12.0167L21.333 17.5L18.9997 19.8333ZM9.66634 3.5V0H11.9997V3.5H9.66634ZM16.5497 5.83333L14.858 4.2L17.3663 1.69167L18.9997 3.325L16.5497 5.83333Z"
                fill="#080808"
            />
        </svg>
    );
}

function AutoCleanerIcon({ size = 88 }) {
    return (
        <svg width={size} height={1.02 * size} viewBox="0 0 88 90" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.5 1.35156H75.053V75.9045H0.5V1.35156Z" stroke="#FF9600" />
            <path d="M12.4922 14.0938H87.0452V88.6467H12.4922V14.0938Z" stroke="#FF9600" />
            <path d="M5.99609 6.84766H81.5491V82.4006H5.99609V6.84766Z" fill="#EFBA42" />
            <path
                d="M59.4384 66.0605H28.6326C28.1079 66.0605 27.6582 66.5103 27.6582 67.0349C27.6582 67.6346 28.1079 68.0843 28.6326 68.0843H59.4384C59.9631 68.0843 60.4878 67.6346 60.4878 67.0349C60.4878 66.5103 59.9631 66.0605 59.4384 66.0605Z"
                fill="black"
            />
            <path
                d="M67.1586 61.4125C67.1586 61.3375 67.1586 30.4568 67.1586 30.3818C67.1586 30.2319 67.0836 30.007 66.9337 29.8571C66.9337 29.8571 66.9337 29.8571 66.8588 29.7822C66.8588 29.7822 66.8587 29.7822 66.7838 29.7072C66.7088 29.6323 66.7838 29.7072 66.7089 29.6323C66.6339 29.5573 66.6339 29.6323 66.6339 29.5573C66.5589 29.5573 66.5589 29.5573 66.5589 29.4824C66.484 29.4824 66.5589 29.4824 66.484 29.4074C66.484 29.4074 66.484 29.4074 66.409 29.4074C66.3341 29.4074 66.409 29.4074 66.3341 29.4074C66.2591 29.4074 66.2591 29.4074 66.2591 29.4074H66.1842C20.3877 29.4074 21.7368 29.3325 21.512 29.4824C21.512 29.4824 21.437 29.4824 21.437 29.5573L21.3621 29.6323L21.2871 29.7072L21.2122 29.7822L21.1372 29.8571C20.9873 30.007 20.9873 30.2319 20.9873 30.3818C20.9873 62.5368 20.9873 61.3375 21.0623 61.5624C21.0623 61.6373 21.0623 61.6374 21.0623 61.6374C21.0623 61.7123 21.0623 61.7123 21.1372 61.7123C21.1372 61.7123 21.1372 61.7123 21.2122 61.7873C21.3621 61.9372 21.5869 62.0871 21.8867 62.162C21.9617 62.162 66.3341 62.162 66.409 62.162C66.7838 62.237 67.0836 61.8622 67.1586 61.4125ZM52.3928 48.9702C52.018 48.6704 51.3434 48.6704 50.9686 49.1951C50.6688 49.5699 50.6688 50.2444 51.1935 50.5443L63.4859 60.3631H24.66L36.9524 50.5443C37.3271 50.2444 37.4021 49.6448 37.1023 49.1951C36.8025 48.6704 36.2028 48.6704 35.7531 48.9702L22.8611 59.3138V32.4805L41.0748 46.7216C42.8737 48.0708 45.3471 48.0708 47.0711 46.7216L65.2098 32.4805V59.3138L52.3928 48.9702ZM24.735 31.4312H63.4109L45.8718 45.1476C44.8225 45.9721 43.3984 45.9721 42.349 45.1476L24.735 31.4312Z"
                fill="black"
            />
            <path d="M35.3779 56.4668H37.2518V58.4156H35.3779V56.4668Z" fill="black" />
            <path d="M39.2012 56.4668H41.075V58.4156H39.2012V56.4668Z" fill="black" />
            <path d="M43.0986 56.4668H44.9725V58.4156H43.0986V56.4668Z" fill="black" />
            <path d="M46.9209 56.4668H48.7947V58.4156H46.9209V56.4668Z" fill="black" />
            <path d="M50.7441 56.4668H52.6929V58.4156H50.7441V56.4668Z" fill="black" />
            <path
                d="M43.9226 26.6349C44.4473 26.6349 44.897 26.1852 44.897 25.6605V22.8123C44.897 22.2876 44.4473 21.8379 43.9226 21.8379C43.398 21.8379 42.9482 22.2876 42.9482 22.8123V25.6605C42.9482 26.1852 43.398 26.6349 43.9226 26.6349Z"
                fill="black"
            />
            <path
                d="M38.3769 26.3344C38.7516 26.7092 39.3513 26.7092 39.726 26.3344C40.1008 25.9596 40.1008 25.36 39.726 24.9852L37.0277 22.2869C36.6529 21.9122 36.0533 21.9122 35.6785 22.2869C35.3038 22.6617 35.3038 23.2613 35.6785 23.6361L38.3769 26.3344Z"
                fill="black"
            />
            <path
                d="M49.3949 26.3344L52.1682 23.6361C52.5429 23.2613 52.5429 22.6617 52.1682 22.2869C51.7934 21.9122 51.1938 21.9122 50.744 22.2869L48.0457 24.9852C47.671 25.36 47.671 25.9596 48.0457 26.3344C48.4205 26.7092 49.0201 26.7092 49.3949 26.3344Z"
                fill="black"
            />
        </svg>
    );
}

function AutoCleanerTablePreview() {
    return (
        <Box
            sx={{
                boxShadow: "0px 0px 16px 0px #00000024",
            }}
        >
            <Grid2 container padding={"10px"} alignItems={"center"}>
                <Grid2 size={3}>
                    <Typography fontSize={"12px"} fontWeight={400}>
                        Sender’s email
                    </Typography>
                </Grid2>
                <Grid2 size={2}>
                    <Typography fontSize={"12px"} fontWeight={400}>
                        Emails received
                    </Typography>
                </Grid2>
                <Grid2 size={2}>
                    <Typography fontSize={"12px"} fontWeight={400}>
                        Last email received
                    </Typography>
                </Grid2>
            </Grid2>
            <Grid2
                container
                alignItems={"center"}
                padding={"10px"}
                sx={{
                    background: "#F9FAFB",
                }}
            >
                <Grid2 size={3}>
                    <Typography fontWeight={500} fontSize={"14px"}>
                        updates@newsletter.com
                    </Typography>
                </Grid2>
                <Grid2 size={2}>
                    <Typography fontWeight={500} fontSize={"14px"}>
                        421
                    </Typography>
                </Grid2>
                <Grid2 size={2}>
                    <Typography fontWeight={500} fontSize={"14px"}>
                        5 hrs ago
                    </Typography>
                </Grid2>
                <Grid2 size={5}>
                    <Box display={"flex"} gap={"12px"} alignItems={"center"} justifyContent={"flex-end"}>
                        <Box>
                            <Button
                                variant="contained"
                                sx={{
                                    background: "#FFAE02",
                                    borderRadius: "4px",
                                    height: "40px",
                                }}
                                fullWidth
                                disableRipple
                                disableElevation
                                startIcon={<ZapSenderIcon size={30} />}
                            >
                                <Typography fontWeight={500} color="#000000" lineHeight={"16.94px"} variant={"body2"}>
                                    Zap the mail
                                </Typography>
                                <Box
                                    sx={{
                                        position: "absolute",
                                        bottom: 0,
                                        right: 0,
                                    }}
                                >
                                    <ClickIcon />
                                </Box>
                            </Button>
                        </Box>
                        <Box>
                            <Button
                                disableElevation
                                disableRipple
                                variant="contained"
                                fullWidth
                                sx={{
                                    background: "#EFBA421A",
                                    height: "40px",
                                    border: "1px solid #EFBA42",
                                }}
                                startIcon={<EditIcon size={20} />}
                            >
                                <Typography color="#000000" lineHeight={"16.94px"} variant={"body2"}>
                                    Add custom rule
                                </Typography>
                            </Button>
                        </Box>
                    </Box>
                </Grid2>
            </Grid2>
        </Box>
    );
}

function AutoCleanerCardPreview() {
    return (
        <Box>
            <Divider
                sx={{
                    border: "1px solid #E6E6E6",
                }}
            />
            <Box
                sx={{
                    width: "100%",
                    boxShadow: "0px 1px 1px 0px #0000001A",
                    border: 0,
                    borderRadius: 0,
                    background: "white",
                }}
            >
                <Box p={1}>
                    <Box display={"flex"} alignItems={"center"} gap={"8px"} marginBottom={"10px"}>
                        <MailOutlineIcon
                            sx={{
                                color: "#111827",
                            }}
                        />
                        <Typography color="##111827" variant="subtitle2" fontWeight={500} lineHeight={"16.94px"} noWrap>
                            updates@newsletter.com
                        </Typography>
                    </Box>
                    <Box display={"flex"} gap={"8px"} marginBottom={"20px"}>
                        <Typography color="#616265" variant="body2">
                            Mails: <strong>2,433</strong>
                        </Typography>
                        <Typography color="#616265" variant="body2">
                            Read: <strong>20%</strong>
                        </Typography>
                    </Box>
                    <Box display={"flex"} gap={"18px"} alignItems={"center"}>
                        <Box width={"170px"}>
                            <Button
                                variant="contained"
                                sx={{
                                    background: "#FFAE02",
                                    borderRadius: "4px",
                                    height: "30px",
                                }}
                                fullWidth
                                disableRipple
                                disableElevation
                                startIcon={<ZapSenderIcon size={20} />}
                            >
                                <Typography fontWeight={500} color="#000000" lineHeight={"16.94px"} variant={"caption"}>
                                    Zap the mail
                                </Typography>
                                <Box
                                    sx={{
                                        position: "absolute",
                                        bottom: 0,
                                        right: 0,
                                    }}
                                >
                                    <ClickIcon />
                                </Box>
                            </Button>
                        </Box>
                        <Box width={"170px"}>
                            <Button
                                disableElevation
                                disableRipple
                                variant="contained"
                                fullWidth
                                sx={{
                                    background: "#EFBA421A",
                                    height: "30px",
                                    border: "1px solid #EFBA42",
                                }}
                                startIcon={<EditIcon size={16} />}
                            >
                                <Typography color="#000000" lineHeight={"16.94px"} variant={"caption"}>
                                    Custom rule
                                </Typography>
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export default function AutoCleanerPreview({ open, onNext }) {
    const { md } = useCustomMediaQuery();
    return (
        <Dialog
            open={open}
            fullScreen={!md}
            sx={{
                backdropFilter: "blur(4px)",
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: md ? "988px" : "100%",
                        maxHeight: md ? "732px" : "none",
                        boxShadow: md
                            ? "0px 1px 40px 0px #FF9600 inset; 0px 4px 18px 0px #FF9600 inset"
                            : "0px 1px 20px 0px #FF9600 inset; 0px 0px 10px 0px #FF9600 inset",
                        borderRadius: md ? "20px" : 0,
                        padding: md ? "80px 40px 40px 40px" : "20px",
                    },
                },
                background:
                    "linear-gradient(108.46deg, rgba(255, 255, 255, 0.264) 0%, rgba(255, 255, 255, 0.066) 100%)",
            }}
        >
            <Box display={"flex"} flexDirection={"column"} gap={"32px"} height={"max(100%, 775px)"}>
                <Box>
                    <Box>
                        <Typography
                            gutterBottom
                            fontSize={md ? "20px" : "18px"}
                            fontWeight={600}
                            sx={{ color: "#1F2337" }}
                        >
                            EmailZap welcomes you to your CLEANEST inbox yet
                        </Typography>
                        <Typography fontWeight={400} fontSize={"14px"} sx={{ color: "#1C1D31" }}>
                            Say goodbye to clutter and distractions—these powerful new tools are here to keep your inbox
                            focused on what matters most. Explore the new features below, designed to enhance your email
                            experience and boost productivity.
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            marginTop: md ? "60px" : "20px",
                            padding: md ? "16px 40.28px 16px 24px" : "4px",
                            borderRadius: "8px",
                            border: "1px solid  #EFBA42",
                            boxShadow: "0px 20px 47px 0px #0000000D",
                            background: "white",
                        }}
                    >
                        <Box
                            display={"flex"}
                            gap={"16px"}
                            alignItems={"center"}
                            marginBottom={"16px"}
                            flexDirection={md ? "row" : "column"}
                        >
                            <Box>
                                <AutoCleanerIcon />
                            </Box>
                            <Box>
                                <Typography
                                    fontSize={"20px"}
                                    fontWeight={600}
                                    gutterBottom
                                    align={md ? "inherit" : "center"}
                                >
                                    Auto Cleaner
                                </Typography>
                                <Typography variant={md ? "body1" : "body2"} align={md ? "inherit" : "center"}>
                                    <Typography
                                        variant={md ? "body1" : "body2"}
                                        display={"inline"}
                                        component={"span"}
                                        fontWeight={500}
                                        align={md ? "inherit" : "center"}
                                    >
                                        Inbox, Uncluttered.{" "}
                                    </Typography>
                                    Unwanted emails will be auto-moved to “Zapped” label. You can use this tab on the
                                    dashboard to change that or move more emails to “Zapped”.{" "}
                                    {md &&
                                        "Note, this will happen for all emails you receive from that sender in the future"}
                                </Typography>
                                {!md && (
                                    <Typography variant={"body2"} align={"center"}>
                                        Note, this will happen for all emails you receive from that sender in the future
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                        {md ? <AutoCleanerTablePreview /> : <AutoCleanerCardPreview />}
                    </Box>
                </Box>
                <Box marginTop={"auto"}>
                    <Button
                        variant="contained"
                        fullWidth
                        disableElevation
                        disableRipple
                        sx={{
                            height: "41px",
                            color: "black",
                            fontWeight: 600,
                            fontSize: "14px",
                        }}
                        onClick={onNext}
                    >
                        Next
                    </Button>
                </Box>
            </Box>
        </Dialog>
    );
}
