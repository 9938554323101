import { Box, Dialog, Typography } from "@mui/material";
import EmailZapIcon from "components/Common/Icons/EmailZapIcon";
import { useRef, useState } from "react";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import LabelOutlinedIcon from "@mui/icons-material/LabelOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import IntroScreen from "./IntroScreen";
import EmailLabellingScreen from "./EmailLabellingScreen";
import DigestSetupScreen from "./DigestSetupScreen";
import { useCustomMediaQuery } from "hooks/useMediaQuery";
import AutoCleanerPreview from "./AutoCleanerPreview";
import DeleteEmailsPreview from "./DeleteEmailsPreview";
import UnsubscriberPreview from "./UnsubscriberPreview";
import SubscriptionSetup from "./SubscriptionSetup";
import { useMailBotMetadata, useShowOnboardingTour } from "hooks/api/mailbot/queries";
import { useUpdateOnboardingTourViewed } from "hooks/api/mailbot/mutations";
import Loader from "components/Common/Loader";
import { useLatestSubscription } from "hooks/api/payments/queries";
import { useLocation } from "react-router-dom";

function RadioIcon({ completed, inProgress }) {
    if (completed) {
        return (
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0 11C0 4.92487 4.92487 0 11 0C17.0751 0 22 4.92487 22 11C22 17.0751 17.0751 22 11 22C4.92487 22 0 17.0751 0 11ZM15.0732 8.95335C15.3448 8.57308 15.2567 8.04462 14.8764 7.77299C14.4962 7.50137 13.9677 7.58945 13.6961 7.96972L10.0459 13.08L8.21371 11.2478C7.88326 10.9174 7.34751 10.9174 7.01706 11.2478C6.68662 11.5783 6.68662 12.114 7.01706 12.4445L9.55552 14.9829C9.7314 15.1588 9.97576 15.2484 10.2236 15.2279C10.4715 15.2074 10.6978 15.0788 10.8424 14.8764L15.0732 8.95335Z"
                    fill="#00D149"
                />
            </svg>
        );
    }
    return (
        <Box
            sx={{
                borderWidth: "1px",
                borderStyle: "solid",
                borderColor: inProgress ? "#00D149" : "#D8DADF",
                width: "22px",
                height: "22px",
                borderRadius: "100px",
                background: "#FFFFFF",
            }}
        ></Box>
    );
}

function OnboardingProgressIcon({ completed }) {
    return (
        <Box
            sx={{
                background: completed
                    ? "linear-gradient(128.49deg, #FFDC8E 19.86%, #EFBA42 68.34%)"
                    : "linear-gradient(128.49deg, rgba(255, 220, 142, 0.18) 19.86%, rgba(239, 186, 66, 0.18) 68.34%)",
                width: "56px",
                height: "10px",
                borderRadius: "10px",
            }}
        ></Box>
    );
}

const Onboardingsteps = [
    { title: "Intro", renderIcon: () => <PersonOutlineIcon /> },
    { title: "Email labelling", renderIcon: () => <LabelOutlinedIcon /> },
    { title: "Setup email digest", renderIcon: () => <EmailOutlinedIcon /> },
];

function OnboardingSideBar({ currentStep }) {
    return (
        <Box
            width={"360px"}
            height={"800px"}
            sx={{
                background: "linear-gradient(180deg, rgba(206, 251, 164, 0.0133333) 0%, rgba(239, 186, 66, 0.1) 100%)",
                boxShadow: "0px 2px 10px 0px #0000000D",
            }}
            display={"flex"}
            justifyContent={"space-between"}
            flexDirection={"column"}
        >
            <Box>
                {Onboardingsteps.map((step, stepNumber) => (
                    <Box
                        sx={{
                            borderStyle: "solid",
                            borderColor: stepNumber === currentStep ? "#FFDC8E" : "transparent",
                            borderWidth: "0 0 0 10px",
                            borderTopLeftRadius: "4px",
                            borderBottomLeftRadius: "4px",
                        }}
                        key={step.title}
                        width={"344px"}
                        height={"74px"}
                        display={"flex"}
                        padding={"24px"}
                        justifyContent={"space-between"}
                    >
                        <Box display={"flex"} gap={"8px"}>
                            {step.renderIcon()}
                            <Typography fontWeight={600} variant="subtitle2">
                                {step.title}
                            </Typography>
                        </Box>
                        <RadioIcon completed={stepNumber < currentStep} inProgress={stepNumber === currentStep} />
                    </Box>
                ))}
            </Box>
            <Box
                width={"352px"}
                height={"17px"}
                gap={"10px"}
                display={"flex"}
                alignItems={"center"}
                p={1}
                marginBottom={"32px"}
            >
                <Typography>
                    {currentStep + 1}/{Onboardingsteps.length}
                </Typography>
                <Box display={"flex"} gap={"40px"}>
                    {Onboardingsteps.map((step, stepNumber) => (
                        <OnboardingProgressIcon completed={stepNumber < currentStep} />
                    ))}
                </Box>
            </Box>
        </Box>
    );
}

function OnboardingNavBar({ currentStep, scrollToRef }) {
    return (
        <Box
            display={"flex"}
            gap="16px"
            sx={{
                overflowX: "scroll",
                scrollbarWidth: "none",
            }}
        >
            {Onboardingsteps.map((step, stepNumber) => (
                <Box display={"flex"}>
                    <Box
                        sx={{
                            height: "45px",
                            background: "rgba(255, 220, 142, 0.1)",
                            borderStyle: "solid",
                            minWidth: "188px",
                            borderColor: stepNumber === currentStep ? "#FFDC8E" : "transparent",
                            borderWidth: "0 0 4px 0",
                            borderTopRightRadius: "4px",
                            borderBottomLeftRadius: "4px",
                        }}
                        ref={stepNumber === currentStep + 1 ? scrollToRef : null} // We want to scroll to next element when next is clicked
                        padding={"10px"}
                        key={step.title}
                        display={"flex"}
                        justifyContent={"space-between"}
                        gap={"8px"}
                    >
                        <Box display={"flex"} gap={"8px"}>
                            {step.renderIcon()}
                            <Typography fontWeight={600} variant="subtitle2" noWrap>
                                {step.title}
                            </Typography>
                        </Box>
                        <RadioIcon completed={stepNumber < currentStep} inProgress={stepNumber === currentStep} />
                    </Box>
                    <Box
                        sx={{
                            height: "45px",
                            aspectRatio: "cos(80deg)",
                            clipPath: "polygon(0 0,100% 50%,0 100%)",
                            background: "rgba(255, 220, 142, 0.1)",
                        }}
                    ></Box>
                </Box>
            ))}
        </Box>
    );
}

export default function Onboarding() {
    const { data: mailBotProfileMetadata, isPending } = useMailBotMetadata();
    const { data: showOnboardingTourData, isPending: showOnboardingTourPending } = useShowOnboardingTour();
    const { data: subscription, isPending: subscriptionPending } = useLatestSubscription();
    const onboardingTourViewedMutation = useUpdateOnboardingTourViewed();
    const location = useLocation();
    const scrollToRef = useRef<any>(null);
    const scrollToElement = () => {
        if (scrollToRef.current) {
            scrollToRef.current.scrollIntoView({
                behavior: "smooth",
                inline: "center",
            });
        }
    };
    const { md } = useCustomMediaQuery();
    if (isPending || showOnboardingTourPending) {
        return null;
    }
    let currentStep = 0;
    if (
        mailBotProfileMetadata.last_onboarding_tour_step_viewed !== undefined &&
        mailBotProfileMetadata.last_onboarding_tour_step_viewed !== null
    ) {
        currentStep = mailBotProfileMetadata.last_onboarding_tour_step_viewed + 1;
    }
    const onNext = () => {
        onboardingTourViewedMutation.mutate({ latestScreenViewed: currentStep });
        scrollToElement();
    };
    let showOnboardingTourInQueryParam = false;
    if (location.state) {
        showOnboardingTourInQueryParam = location.state.showOnboardingTour === "True";
    }
    const showOnboardingTour =
        (showOnboardingTourData || showOnboardingTourInQueryParam) && !location.pathname.startsWith("/completion");
    return (
        <>
            <Dialog open={showOnboardingTour && currentStep < 3} fullScreen={true}>
                <Box
                    sx={{
                        height: "100%",
                        width: "100%",
                        maxWidth: md ? "1160px" : "375px",
                        margin: "auto",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                    }}
                >
                    <Box width={"100%"} maxHeight={"880px"} height={"100%"}>
                        <Box p={2}>
                            <EmailZapIcon size={md ? 52 : 32} />
                        </Box>
                        <Box
                            display={"flex"}
                            gap={"32px"}
                            flexDirection={md ? "row" : "column"}
                            padding={md ? 0 : "10px"}
                        >
                            {md ? (
                                <OnboardingSideBar currentStep={currentStep} />
                            ) : (
                                <OnboardingNavBar scrollToRef={scrollToRef} currentStep={currentStep} />
                            )}
                            {currentStep === 0 && <IntroScreen onNext={onNext} />}
                            {currentStep === 1 && <EmailLabellingScreen onNext={onNext} />}
                            {currentStep === 2 && <DigestSetupScreen onNext={onNext} />}
                        </Box>
                    </Box>
                </Box>
            </Dialog>
            <AutoCleanerPreview onNext={onNext} open={showOnboardingTour && currentStep === 3} />
            <DeleteEmailsPreview onNext={onNext} open={showOnboardingTour && currentStep === 4} />
            <UnsubscriberPreview onNext={onNext} open={showOnboardingTour && currentStep === 5} />
            <SubscriptionSetup
                open={
                    showOnboardingTour &&
                    currentStep === 6 &&
                    !subscriptionPending &&
                    subscription?.price.nickname === "freebie"
                }
                onNext={onNext}
            />
            {onboardingTourViewedMutation.isPending && <Loader />}
        </>
    );
}
