export default function CheckIcon({ size = 32 }) {
    return (
        <svg width={1.03 * size} height={size} viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.333984" width="32" height="32" rx="16" fill="#E8EDFB" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22.1502 12.207C22.4041 12.4737 22.3937 12.8957 22.127 13.1495L14.4233 20.4829C14.2917 20.6081 14.1148 20.6742 13.9333 20.666C13.7519 20.6577 13.5816 20.5758 13.462 20.4391L10.499 17.0545C10.2565 16.7775 10.2845 16.3563 10.5615 16.1138C10.8386 15.8713 11.2598 15.8992 11.5023 16.1763L14.0074 19.0379L21.2077 12.1838C21.4744 11.9299 21.8963 11.9403 22.1502 12.207Z"
                fill="#FF9600"
            />
        </svg>
    );
}
