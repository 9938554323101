import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate } from "react-router-dom";
import redditTrackingJs from "scripts/redditTracking";

export default function CheckoutCompletion() {
    const [redirectTimer, setRedirectTimer] = useState(5);
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const isFreeUser = queryParams.get("price") === "freebie";
    useEffect(() => {
        const timerInterval = setInterval(() => {
            if (redirectTimer === 1) {
                clearInterval(timerInterval);
                const showOnboardingTour = queryParams.get("show_onboarding_tour");
                navigate(
                    { pathname: "/" },
                    {
                        replace: true,
                        state: {
                            showOnboardingTour,
                        },
                    },
                );
            } else {
                setRedirectTimer(redirectTimer - 1);
            }
        }, 1000);

        return () => {
            clearInterval(timerInterval);
        };
    }, [redirectTimer]);

    return (
        <>
            <Helmet>
                <script type="text/javascript">{redditTrackingJs}</script>
            </Helmet>
            <div className="flex flex-col items-center justify-center h-screen">
                {!isFreeUser && (
                    <>
                        <div className="mb-4 text-2xl font-semibold text-green-500">Payment Successful</div>
                        <svg
                            className="w-12 h-12 text-green-500"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                        </svg>
                    </>
                )}
                {isFreeUser && (
                    <div className="mb-4 text-xl md:text-2xl font-semibold text-center">
                        Setup in progress. Emailzap will be ready shortly.
                    </div>
                )}
                <p className="mt-4 text-center">Please wait while we redirect you in {redirectTimer} seconds...</p>
            </div>
        </>
    );
}
