import { Box, Button, Dialog, Divider, Grid2, Typography } from "@mui/material";
import DeleteIcon from "components/Common/Icons/DeleteIcon";
import MailInfoIcon from "components/Common/Icons/MailInfoIcon";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { useCustomMediaQuery } from "hooks/useMediaQuery";

function ClickIcon() {
    return (
        <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.333008 10.5V8.16667H3.83301V10.5H0.333008ZM4.29967 16.975L2.66634 15.2833L5.11634 12.8333L6.80801 14.4667L4.29967 16.975ZM5.11634 5.83333L2.66634 3.38333L4.29967 1.69167L6.80801 4.2L5.11634 5.83333ZM18.9997 19.8333L13.458 14.2917L11.9997 18.6667L8.49967 7L20.1663 10.5L15.8497 12.0167L21.333 17.5L18.9997 19.8333ZM9.66634 3.5V0H11.9997V3.5H9.66634ZM16.5497 5.83333L14.858 4.2L17.3663 1.69167L18.9997 3.325L16.5497 5.83333Z"
                fill="#080808"
            />
        </svg>
    );
}

function DeleteEmailsIcon({ size = 88 }) {
    return (
        <svg width="88" height="89" viewBox="0 0 88 89" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.727539 0.5H75.2805V75.053H0.727539V0.5Z" stroke="#FF9600" />
            <path d="M12.7197 13.2422H87.2727V87.7952H12.7197V13.2422Z" stroke="#FF9600" />
            <path d="M6.22363 5.99609H81.7766V81.5491H6.22363V5.99609Z" fill="#EFBA42" />
            <path
                d="M59.666 65.2832H28.8601C28.3355 65.2832 27.8857 65.7329 27.8857 66.2576C27.8857 66.7823 28.3355 67.232 28.8601 67.232H59.666C60.1907 67.232 60.6404 66.7823 60.6404 66.2576C60.6404 65.7329 60.1907 65.2832 59.666 65.2832Z"
                fill="black"
            />
            <path
                d="M67.3861 60.6367C67.3861 60.5617 67.3861 29.6809 67.3861 29.606C67.3861 29.4561 67.3112 29.2312 67.1613 29.0813C67.1613 29.0813 67.1613 29.0813 67.0863 29.0064C67.0863 29.0064 67.0863 29.0064 67.0113 28.9314C66.9364 28.8565 67.0113 28.9314 66.9364 28.8564C66.8614 28.7815 66.8614 28.8564 66.8614 28.7815C66.7865 28.7815 66.7865 28.7815 66.7865 28.7065C66.7115 28.7065 66.7865 28.7066 66.7115 28.6316C66.4867 28.5566 66.8614 28.5566 54.8689 28.5566C54.3442 28.5566 53.8196 29.0064 53.8196 29.531C53.8196 30.0557 54.3442 30.5054 54.8689 30.5054H63.6384L44.2255 45.646L24.8876 30.5054H33.6571C34.1818 30.5054 34.6315 30.0557 34.6315 29.531C34.6315 29.0064 34.1818 28.5566 33.6571 28.5566C21.3648 28.5566 22.0393 28.5566 21.8894 28.5566H21.8145H21.7395C21.7395 28.5566 21.6646 28.5566 21.6646 28.6316L21.5896 28.7065L21.5147 28.7815L21.4397 28.8564L21.3648 28.9314C21.2149 29.0813 21.2148 29.3062 21.2148 29.531C21.2148 61.686 21.2148 60.4868 21.2898 60.7116C21.2898 60.7866 21.2898 60.7866 21.2898 60.7866C21.2898 60.8615 21.2898 60.8615 21.3648 60.8615C21.3648 60.8615 21.3648 60.8615 21.4397 60.9365C21.5896 61.0864 21.8145 61.2363 22.1143 61.3112C22.1892 61.3112 66.5616 61.3112 66.6366 61.3112C67.0113 61.4611 67.3112 61.0864 67.3861 60.6367ZM44.9001 47.7447L65.4373 31.6297V58.463L52.5454 48.1944C52.1706 47.8946 51.496 47.8946 51.1962 48.3443C50.8964 48.7191 50.8964 49.3937 51.3461 49.6935L63.7134 59.5124H24.8126L37.1799 49.6935C37.5547 49.3937 37.6296 48.794 37.3298 48.3443C37.03 47.8946 36.4304 47.8946 35.9807 48.1944L23.0887 58.463V31.6297L43.7008 47.7447C44.0007 48.0445 44.4504 48.0445 44.9001 47.7447Z"
                fill="black"
            />
            <path d="M35.5312 55.6895H37.48V57.5633H35.5312V55.6895Z" fill="black" />
            <path d="M39.4287 55.6895H41.3025V57.5633H39.4287V55.6895Z" fill="black" />
            <path d="M43.251 55.6895H45.1998V57.5633H43.251V55.6895Z" fill="black" />
            <path d="M47.1484 55.6895H49.0223V57.5633H47.1484V55.6895Z" fill="black" />
            <path d="M50.9717 55.6895H52.8455V57.5633H50.9717V55.6895Z" fill="black" />
            <path
                d="M37.5545 29.6809H37.6294L38.5289 38.9002C38.5289 40.1744 39.5782 41.2237 40.8524 41.2237H47.6732C48.9474 41.2237 49.9967 40.1744 49.9967 38.9002L50.8962 29.6809H50.9711C51.4958 29.6809 51.9455 29.2312 51.9455 28.7065V24.8839C51.9455 24.3592 51.4958 23.9095 50.9711 23.9095H48.1229V21.9607C48.1229 21.436 47.6732 20.9863 47.1485 20.9863H41.3771C40.8524 20.9863 40.4027 21.436 40.4027 21.9607V23.9095H37.5545C37.0298 23.9095 36.5801 24.3592 36.5801 24.8839V28.7065C36.5801 29.2312 37.0298 29.6809 37.5545 29.6809ZM48.1229 38.9002C48.1229 39.125 47.898 39.3499 47.6732 39.3499H40.8524C40.6276 39.3499 40.4027 39.125 40.4027 38.9002C40.4027 38.8252 40.4777 39.4249 39.5782 29.6809H49.0223C48.0479 39.4249 48.1229 38.7503 48.1229 38.9002ZM42.3515 22.9351H46.2491V23.9845H42.3515V22.9351ZM38.5289 25.7833C41.0773 25.7833 47.4483 25.7833 50.0717 25.7833V27.6572H38.5289V25.7833Z"
                fill="black"
            />
            <path
                d="M44.2254 38.3765C44.7501 38.3765 45.2747 37.9267 45.2747 37.4021V31.6307C45.2747 31.106 44.7501 30.6562 44.2254 30.6562C43.7007 30.6562 43.251 31.106 43.251 31.6307V37.4021C43.251 37.9267 43.7007 38.3765 44.2254 38.3765Z"
                fill="black"
            />
        </svg>
    );
}

function DeleteEmailsTablePreview() {
    return (
        <Box
            sx={{
                boxShadow: "0px 0px 16px 0px #00000024",
            }}
        >
            <Grid2 container padding={"10px"} alignItems={"center"}>
                <Grid2 size={3}>
                    <Typography fontSize={"12px"} fontWeight={400}>
                        Sender’s email
                    </Typography>
                </Grid2>
                <Grid2 size={2}>
                    <Typography fontSize={"12px"} fontWeight={400}>
                        Emails received
                    </Typography>
                </Grid2>
                <Grid2 size={2}>
                    <Typography fontSize={"12px"} fontWeight={400}>
                        Last email received
                    </Typography>
                </Grid2>
            </Grid2>
            <Grid2
                container
                padding={"10px"}
                sx={{
                    background: "#F9FAFB",
                }}
                alignItems={"center"}
            >
                <Grid2 size={3}>
                    <Typography fontWeight={500} fontSize={"14px"}>
                        updates@newsletter.com
                    </Typography>
                </Grid2>
                <Grid2 size={2}>
                    <Typography fontWeight={500} fontSize={"14px"}>
                        421
                    </Typography>
                </Grid2>
                <Grid2 size={2}>
                    <Typography fontWeight={500} fontSize={"14px"}>
                        5 hrs ago
                    </Typography>
                </Grid2>
                <Grid2 size={5}>
                    <Box display={"flex"} gap={"32px"} alignItems={"center"} justifyContent={"flex-end"}>
                        <Box>
                            <Box display={"flex"} justifyContent="center" alignItems="center" gap={"4px"}>
                                <MailInfoIcon size={20} color={"black"} />

                                <Typography fontSize={"12px"} fontWeight={500} lineHeight={"14.52px"} color={"black"}>
                                    Zapped by you
                                </Typography>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                width: "100px",
                            }}
                        >
                            <Button
                                disableElevation
                                disableRipple
                                fullWidth
                                sx={{
                                    background: "#FFAE02",
                                    border: "1px solid #EFBA42",
                                    borderRadius: "4px",
                                    height: "40px",
                                }}
                                variant="contained"
                                startIcon={<DeleteIcon size={16} />}
                            >
                                <Typography color="#1B1301" variant={"body2"}>
                                    Delete
                                </Typography>
                                <Box
                                    sx={{
                                        position: "absolute",
                                        bottom: 0,
                                        right: 0,
                                    }}
                                >
                                    <ClickIcon />
                                </Box>
                            </Button>
                        </Box>
                    </Box>
                </Grid2>
            </Grid2>
        </Box>
    );
}

function DeleteEmailsCardPreview() {
    return (
        <Box>
            <Divider
                sx={{
                    border: "1px solid #E6E6E6",
                }}
            />
            <Box
                sx={{
                    width: "100%",
                    boxShadow: "0px 1px 1px 0px #0000001A",
                    border: 0,
                    borderRadius: 0,
                    background: "white",
                }}
            >
                <Box p={1}>
                    <Box display={"flex"} alignItems={"center"} gap={"8px"} marginBottom={"10px"}>
                        <MailOutlineIcon
                            sx={{
                                color: "#111827",
                            }}
                        />
                        <Typography color="##111827" variant="subtitle2" fontWeight={500} lineHeight={"16.94px"} noWrap>
                            marketing@creditcards.com
                        </Typography>
                    </Box>
                    <Box display={"flex"} gap={"8px"} marginBottom={"20px"}>
                        <Typography color="#616265" variant="body2">
                            Mails: <strong>2,433</strong>
                        </Typography>
                        <Typography color="#616265" variant="body2">
                            Read: <strong>20%</strong>
                        </Typography>
                    </Box>
                    <Box display={"flex"} gap={"18px"} justifyContent={"space-between"} alignItems={"center"}>
                        <Box display={"flex"} justifyContent="center" alignItems="center" gap={"4px"}>
                            <MailInfoIcon size={20} color={"black"} />
                            <Typography fontSize={"12px"} fontWeight={500} lineHeight={"14.52px"} color={"black"}>
                                Zapped by you
                            </Typography>
                        </Box>
                        <Button
                            disableElevation
                            disableRipple
                            sx={{
                                background: "rgba(239, 186, 66, 0.1)",
                                borderRadius: "4px",
                                width: "100px",
                                height: "30px",
                            }}
                            variant="contained"
                            startIcon={<DeleteIcon size={16} />}
                        >
                            <Typography color="#1B1301" variant={"caption"}>
                                Delete
                            </Typography>
                            <Box
                                sx={{
                                    position: "absolute",
                                    bottom: 0,
                                    right: 0,
                                }}
                            >
                                <ClickIcon />
                            </Box>
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export default function DeleteEmailsPreview({ open, onNext }) {
    const { md } = useCustomMediaQuery();
    return (
        <Dialog
            open={open}
            fullScreen={!md}
            sx={{
                backdropFilter: "blur(4px)",
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: md ? "988px" : "100%",
                        maxHeight: md ? "732px" : "none",
                        boxShadow: md
                            ? "0px 1px 40px 0px #FF9600 inset; 0px 4px 18px 0px #FF9600 inset"
                            : "0px 1px 20px 0px #FF9600 inset; 0px 0px 10px 0px #FF9600 inset",
                        borderRadius: md ? "20px" : 0,
                        padding: md ? "80px 40px 40px 40px" : "20px",
                    },
                },
                background:
                    "linear-gradient(108.46deg, rgba(255, 255, 255, 0.264) 0%, rgba(255, 255, 255, 0.066) 100%)",
            }}
        >
            <Box display={"flex"} flexDirection={"column"} gap={"32px"} height={"max(100%, 775px)"}>
                <Box>
                    <Box>
                        <Typography fontSize={md ? "20px" : "18px"} fontWeight={600} sx={{ color: "#1F2337" }}>
                            EmailZap welcomes you to your CLEANEST inbox yet
                        </Typography>
                        <Typography fontWeight={400} fontSize={"14px"} sx={{ color: "#1C1D31" }}>
                            Say goodbye to clutter and distractions—these powerful new tools are here to keep your inbox
                            focused on what matters most. Explore the new features below, designed to enhance your email
                            experience and boost productivity.
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            marginTop: md ? "60px" : "20px",
                            padding: md ? "16px 40.28px 16px 24px" : "4px",
                            borderRadius: "8px",
                            border: "1px solid  #EFBA42",
                            boxShadow: "0px 20px 47px 0px #0000000D",
                            background: "white",
                        }}
                    >
                        <Box
                            display={"flex"}
                            gap={"16px"}
                            alignItems={"center"}
                            marginBottom={"16px"}
                            flexDirection={md ? "row" : "column"}
                        >
                            <Box>
                                <DeleteEmailsIcon />
                            </Box>
                            <Box>
                                <Typography
                                    fontSize={"20px"}
                                    fontWeight={600}
                                    gutterBottom
                                    align={md ? "inherit" : "center"}
                                >
                                    Delete emails
                                </Typography>
                                <Typography align={md ? "inherit" : "center"} variant={md ? "body1" : "body2"}>
                                    <Typography
                                        display={"inline"}
                                        component={"span"}
                                        fontWeight={500}
                                        variant={md ? "body1" : "body2"}
                                    >
                                        Goodbye, Repeated Spam!{" "}
                                    </Typography>
                                    This bulk-delete feature not only clears out the clutter but ensures future emails
                                    from specific senders won’t ever appear again. Your inbox, your rules
                                </Typography>
                            </Box>
                        </Box>
                        {md ? <DeleteEmailsTablePreview /> : <DeleteEmailsCardPreview />}
                    </Box>
                </Box>
                <Box marginTop={"auto"}>
                    <Button
                        variant="contained"
                        fullWidth
                        disableElevation
                        disableRipple
                        sx={{
                            height: "41px",
                            color: "black",
                            fontWeight: 600,
                            fontSize: "14px",
                        }}
                        onClick={onNext}
                    >
                        Next
                    </Button>
                </Box>
            </Box>
        </Dialog>
    );
}
