import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { useMailBotMetadata, useMailBotServiceProvider } from "hooks/api/mailbot/queries";
import { loginMailBotProfile } from "utils/login";

/**
 * Currently made this component uncontrolled, as the dialog is only shown when the user has not granted all the scopes.
 * We can make it controlled and reduce the complexity of the component by passing the open state, onClose handler, etc.
 */
export default function InsufficientScopesDialog() {
    const { data: mailbotMetadata, isPending: mailbotMetadataPending } = useMailBotMetadata();
    const { data: serviceProvider, isPending: serviceProviderPending } = useMailBotServiceProvider();
    if (mailbotMetadataPending || serviceProviderPending) {
        return null;
    }
    let areAllScopesGranted = true;
    if (mailbotMetadata.all_scopes_granted === false) {
        areAllScopesGranted = false;
    }
    return (
        <Dialog open={!areAllScopesGranted}>
            <DialogTitle>Insufficient Permissions</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Please grant all permissions for EmailZap to work. Click on the button below to proceed.
                </DialogContentText>
                <DialogActions>
                    {serviceProvider === "google" && (
                        <img
                            className="mx-auto cursor-pointer"
                            src="/images/btn_google_signin_light_normal_web_short.png"
                            alt="Authorize in with google"
                            onClick={() => loginMailBotProfile("google")}
                            data-testid="authorize_gmail_button"
                        />
                    )}
                    {serviceProvider === "microsoft" && (
                        <img
                            className="mx-auto cursor-pointer"
                            src="/images/ms-symbollockup_signin_dark.png"
                            alt="Authorize in with Microsoft"
                            data-testid="authorize_outlook_button"
                            onClick={() => {
                                loginMailBotProfile("microsoft");
                            }}
                        />
                    )}
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
}
