import { useMutation, useQueryClient } from "@tanstack/react-query";
import { mailbotKeys } from "queryKeyFactory";
import {
    activateMailBotProfile,
    createCustomWorkflow,
    deactivateMailBotProfile,
    deleteHistoricalEmails,
    deleteCustomWorkflow,
    deleteUserMailBotProfile,
    mailOperations,
    markNewUIViewed,
    sendSupportEmail,
    unsubscribeSender,
    updateMailBotPreference,
    updateUserTrainingStatus,
    updateOnboardingTourViewed,
    updateUserRole,
} from "services/mailbot/mutations";

export function useUpdateMailBotPreference() {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: updateMailBotPreference,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: mailbotKeys.mailbotProfile() });
        },
    });
}

export function useActivateMailBotProfile() {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: activateMailBotProfile,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: mailbotKeys.mailbotProfile() });
        },
    });
}

export function useDeactivateMailBotProfile() {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: deactivateMailBotProfile,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: mailbotKeys.mailbotProfile() });
        },
    });
}

export function useDeleteUserMailBotProfile() {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: deleteUserMailBotProfile,
        onSuccess: () => {
            queryClient.invalidateQueries();
        },
    });
}

export function useSendSupportEmail() {
    return useMutation({
        mutationFn: sendSupportEmail,
    });
}

export function useDeleteHistoricalEmails() {
    const queryClient = useQueryClient();
    return useMutation({
        mutationKey: mailbotKeys.deleteHistoricalEmails(),
        mutationFn: deleteHistoricalEmails,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: mailbotKeys.senderProfiles() });
            queryClient.invalidateQueries({ queryKey: mailbotKeys.statistics() });
        },
    });
}

export function useUnsubscribeSender() {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: unsubscribeSender,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: mailbotKeys.senderProfiles() });
            queryClient.invalidateQueries({ queryKey: mailbotKeys.statistics() });
        },
    });
}

export function useUpdateUserTrainingStatus() {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: updateUserTrainingStatus,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: mailbotKeys.senderProfiles() });
        },
    });
}

export function useDeleteConfiguredSenderWorkflows() {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: deleteCustomWorkflow,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: mailbotKeys.senderProfiles() });
        },
    });
}

export function useConfigureSenderWorkflows() {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: createCustomWorkflow,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: mailbotKeys.senderProfiles() });
        },
    });
}

export function useMarkNewUIViewed() {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: markNewUIViewed,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: mailbotKeys.showNewUIPopup() });
        },
    });
}

export function useMailBotOperations() {
    return useMutation({
        mutationFn: mailOperations,
    });
}

export function useUpdateOnboardingTourViewed() {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: updateOnboardingTourViewed,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: mailbotKeys.mailbotProfile() });
        },
    });
}

export function useUpdateUserRole() {
    return useMutation({
        mutationFn: updateUserRole,
    });
}
